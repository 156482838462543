import {forwardRef, useEffect, useRef} from 'react';
import {Draggable} from 'gsap/Draggable';
import Inertia from 'gsap/Inertia';
import PropTypes from 'prop-types';
import {gsap} from 'gsap';
import clsx from 'clsx';

// Local styles
import styles from './outletLocationList.module.scss';

gsap.registerPlugin(Draggable, Inertia);

const OutletLocationList = forwardRef((props, ref) => {
	const outletListContainerRef = useRef(null);
	const outletListRef = useRef(null);
	// Hooks
	useEffect(() => {
		Draggable.create(outletListRef.current, {
			bounds: outletListContainerRef.current,
			dragResistance: 0.35,
			cursor: 'move',
			inertia: true,
			type: 'y',
		});
	}, []);
	// Render
	return (
		<div
			ref={ref}
			className={styles.OutletLocationList}>
			<h3 className={styles.OutletLocationList__title}>OUTLET LOCATION</h3>
			<div
				ref={outletListContainerRef}
				className={styles.OutletLocationList__list}>
				<div ref={outletListRef}>
					{
						props.outletsList.map((region, i) => {
							return (
								<div
									className={styles.OutletLocationList__list__item}
									key={i}>
									<p className={styles.OutletLocationList__list__item__number}>{`0${i+1}/`}</p>
									<p
										className={clsx(
											styles.OutletLocationList__list__item__name,
											props.activeLocation === i && styles['OutletLocationList__list__item__name--active'],
										)}
										onClick={() => props.setActiveLocation(i)}>
										{region.city}
									</p>
								</div>
							);
						})
					}
				</div>
			</div>
		</div>
	);
});

OutletLocationList.defaultProps = {
	outletsList: [],
	onClickLocation: () => null,
	setActiveLocation: () => null,
};

OutletLocationList.propTypes = {
	onClickLocation: PropTypes.func,
	activeLocation: PropTypes.number,
	setActiveLocation: PropTypes.func,
	outletsList: PropTypes.arrayOf(PropTypes.object),
};

export default OutletLocationList;
