import {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';

// Local styles
import styles from './content.module.scss';

const Content = props => {
	let progressCount = 0;
	const [progress, setProgress] = useState(progressCount);
	const progressCountRef = useRef(null);
	// Hooks
	useEffect(() => {
		if (props.percentageProgressDuration > 100) {
			progressCountRef.current = setInterval(() => {
				if (progressCount <= 100) {
					setProgress(progressCount);
					progressCount++;
				} else {
					clearInterval(progressCountRef.current);
				}
			}, props.percentageProgressDuration / 100); // Divided by 100 (100% percentage count up)
		}
		return () => clearInterval(progressCountRef.current);
	}, []);
	// Render
	return (
		<div className={styles.Content}>
			<img
				className={styles.Content__moonChickenLogo}
				src='https://res.cloudinary.com/dgsgylfvr/image/upload/c_scale,w_384/v1628572409/hangry-assets/brands/logo/Moon_Chicken_FA-02.png'
				alt="Logo"
			/>
			<div className={styles.Content__progress}>{progress}%</div>
		</div>
	);
};

Content.defaultProps = {
	percentageProgressDuration: 100,
};

Content.propTypes = {
	percentageProgressDuration: PropTypes.number,
};

export default Content;
