import clsx from 'clsx';
import PropTypes from 'prop-types';

// Local components
import OutletLocationMarker from '../OutletLocationMarker';

// Local styles
import styles from './outletLocationMapLegend.module.scss';

const OutletLocationMapLegend = props => {
	return (
		<div className={clsx(
			styles.OutletLocationMapLegend,
			props.className,
		)}>
			<div className={styles.OutletLocationMapLegend__desc}>
				<OutletLocationMarker type='outlet' />
				<p className={styles.OutletLocationMapLegend__desc__text}>
					Moonchicken Outlet
				</p>
			</div>
			<div className={styles.OutletLocationMapLegend__desc}>
				<OutletLocationMarker type='dineIn' />
				<p className={styles.OutletLocationMapLegend__desc__text}>
					Dine In
				</p>
			</div>
		</div>
	);
};

OutletLocationMapLegend.defaultProps = {
	className: '',
};

OutletLocationMapLegend.propTypes = {
	className: PropTypes.string,
};

export default OutletLocationMapLegend;