// Local components
import Gallery from './Gallery';
import Background from './Background';
import Achievements from './Achievements';
import ExplorationDetail from './ExplorationDetail';

// Local styles
import styles from './about.module.scss';

const About = () => {
	return (
		<div className={styles.About}>
			<Background />
			<ExplorationDetail />
			<Achievements />
			<Gallery />
		</div>
	);
};

export default About;
