import {ReactTitle} from 'react-meta-tags';

// Global components
import Ticker from 'components/core/Ticker';
import Header from 'components/others/Header';
import Footer from 'components/others/Footer';
import PreloadScreen from 'components/others/PreloadScreen';

// Local components
import Hero from './Hero';
import About from './About';
import OutletExpansion from './OutletExpansion';

// Local style
import styles from './home.module.scss';

const Home = () => {
	// Render
	return (
		<>
			<ReactTitle title="Moon Chicken" />
			<PreloadScreen>
				<Header className={styles.Home__header} />
				<Ticker
					className={styles.Home__ticker}
					onClick={() => {
						window.open('https://bit.ly/3IRyakE', '_blank');
					}}>
					Discover your <b>Korean Snack Constellation</b>. <u>Take the quiz</u>
				</Ticker>
				<Hero />
				<About />
				<OutletExpansion />
				<Footer />
			</PreloadScreen>
		</>
	);
};

export default Home;
