import {useRef, useEffect} from 'react';

// Local styles
import styles from './background.module.scss';

// Global components
import CloudinaryImage from 'components/core/CloudinaryImage';

// Global assets
import SVGHomeHeroLine from 'assets/vectors/home-hero-line.svg';
import PNGHomeHeroMeteorSmall from 'assets/images/home-hero-meteor-small.png';
import PNGHomeHeroMeteorLarge from 'assets/images/home-hero-meteor-large.png';
import PNGHomeHeroMeteor3 from 'assets/images/menu-meteor-3.png';

// Global utils
import {parallaxMeteor} from 'utils/index';

const Background = () => {
	const smallMeteor = useRef(null);
	const largeMeteor = useRef(null);
	const secondLargeMeteor = useRef(null);
	// Hooks
	useEffect(() => {
		parallaxMeteor({
			smallMeteor: smallMeteor.current,
			largeMeteor: largeMeteor.current,
		});
		parallaxMeteor({
			largeMeteor: secondLargeMeteor.current,
		});
	}, []);
	// Render
	return (
		<div className={styles.Hero__background}>
			<div className={styles.Hero__background__backgroundImage}>
				<CloudinaryImage
					imageId="home-hero-planet"
					className={styles.Hero__background__backgroundImage__planet} />
				<CloudinaryImage
					imageId="home-hero-planet-texture"
					className={styles.Hero__background__backgroundImage__planetTexture} />
			</div>
			<div className={styles.Hero__background__filter}>
				<img
					alt="Line"
					src={SVGHomeHeroLine}
					className={styles.Hero__background__filter__gridLine} />
				<div className={styles.Hero__background__filter__gradientFilterOverlay} />
			</div>
			<div className={styles.Hero__background__meteor} >
				<img
					alt="Meteor Small"
					src={PNGHomeHeroMeteorSmall}
					ref={smallMeteor} />
				<img
					alt="Meteor Large"
					src={PNGHomeHeroMeteorLarge}
					ref={largeMeteor} />
				<img
					alt="Meteor Large"
					src={PNGHomeHeroMeteor3}
					ref={secondLargeMeteor} />
			</div>
		</div>
	);
};

export default Background;
