import {ReactTitle} from 'react-meta-tags';

// Global components
import Header from 'components/others/Header';
import Footer from 'components/others/Footer';
import PreloadScreen from 'components/others/PreloadScreen';

// Local styles
import styles from './outletLocation.module.scss';

// Local components
import OutletLocationContent from './OutletLocationContent';

const OutletLocation = () => {
	return (
		<>
			<ReactTitle title="Outlet Location - Moon Chicken" />
			<PreloadScreen className={styles.OutletLocation}>
				<Header className={styles.OutletLocation__header} />
				<OutletLocationContent />
				<Footer />
			</PreloadScreen>
		</>
	);
};

export default OutletLocation;
