import {useRef} from 'react';
import {useResizeDetector} from 'react-resize-detector';
import {useHistory} from 'react-router-dom';

// Global components
import Button from 'components/core/Button';

// Local styles
import styles from './explorationDetail.module.scss';

const ExplorationDetail = () => {
	const history = useHistory();
	const {width} = useResizeDetector({
		handleHeight: false,
		targetRef: useRef(document.body),
	});
	const enableMagneticButtonEffect = width > parseInt(styles.mobileBreakPoint);
	// Render
	return (
		<div className={styles.ExplorationDetail}>
			<span className={styles.ExplorationDetail__headingText}>
				an <p>exploration</p> of the unknown.
			</span>
			<p className={styles.ExplorationDetail__descriptionText}>
				With Flavors of the Galaxy, Moon Chicken invites our consumers to explore the endless wonders of the universe, bringing both the thrill of a daring challenge and an unforgettable, satisfying experience.
			</p>
			<Button
				label="SEE LOCATIONS"
				id="home-about-see-locations"
				enableMagneticEffect={enableMagneticButtonEffect}
				onClick={() => history.push('outlet-location')}
				className={styles.ExplorationDetail__seeLocationsButton} />
		</div>
	);
};

export default ExplorationDetail;
