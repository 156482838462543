import ReactDOM from 'react-dom';
import {useState, useRef, useEffect} from 'react';
import {useResizeDetector} from 'react-resize-detector';
import GoogleMapReact from 'google-map-react';
import PropTypes from 'prop-types';
import {gsap} from 'gsap';
import clsx from 'clsx';

// Local styles
import styles from './outletLocationMap.module.scss';
import mapStyles from './mapStyles';

// Local components
import OutletLocationMarker from './OutletLocationMarker';
import ZoomControl from './ZoomControl';
import OutletDetails from './OutletDetails';
import OutletLocationList from './OutletLocationList';
import OutletLocationMapLegend from './OutletLocationMapLegend';

// Global utils
import {GOOGLE_MAPS_API_KEY} from 'utils/constants';

function createMapOptions() {
	// next props are exposed at maps
	// "Animation", "ControlPosition", "MapTypeControlStyle", "MapTypeId",
	// "NavigationControlStyle", "ScaleControlStyle", "StrokePosition", "SymbolPath", "ZoomControlStyle",
	// "DirectionsStatus", "DirectionsTravelMode", "DirectionsUnitSystem", "DistanceMatrixStatus",
	// "DistanceMatrixElementStatus", "ElevationStatus", "GeocoderLocationType", "GeocoderStatus", "KmlLayerStatus",
	// "MaxZoomStatus", "StreetViewStatus", "TransitMode", "TransitRoutePreference", "TravelMode", "UnitSystem"
	return {
		styles: mapStyles,
		disableDefaultUI: true,
		fullscreenControl: false,
		backgroundColor: '#10161D',
	};
}

const mapConfigs = {
	defaultCenter: {
		lat: -6.205746,
		lng: 106.817508,
	},
	defaultZoom: 11,
};

const OutletLocationMap = ({outletsData, locationsData}) => {
	const [activeOutlet, setActiveOutlet] = useState(-1);
	const [activeLocation, setActiveLocation] = useState(-1);
	const [mapCenter, setMapCenter] = useState(mapConfigs.defaultCenter);
	const {width} = useResizeDetector({
		targetRef: useRef(document.querySelector('body')),
		handleHeight: false,
	});
	const isDesktopView = width > parseFloat(styles.mobileBreakPoint);
	const transitionDuration = 0.4;
	// Refs
	const outletDetailsContentRef = useRef(null);
	const outletDetailsHeaderRef = useRef(null);
	const backgroundOutletDetail = useRef(null);
	const backgroundOutletList = useRef(null);
	const outletListRef = useRef(null);
	let mapsRef = useRef();
	let mapRef = useRef();
	// Methods
	const handleOnLoad = (map, maps) => {
		const controlButtonDiv = document.createElement('div');
		ReactDOM.render(
			<ZoomControl map={map} />,
			controlButtonDiv);
		map.controls[maps.ControlPosition.RIGHT_CENTER].push(controlButtonDiv);
		mapRef.current = map;
		mapsRef.current = maps;
	};
	const searchActiveLocation = (city, province) => {
		let cityName = city.toLowerCase();
		let provinceName = province.toLowerCase();
		let retIdx = -1;

		locationsData.map((item, i) => {
			let locationName = item.city;

			if (cityName === 'bekasi' || cityName === 'cikarang') {
				cityName = 'bekasi & cikarang';
			}

			if (locationName.toLowerCase() === cityName ||
				locationName.toLowerCase() === provinceName) {
				retIdx = i;
			}
		});
		return retIdx;
	};
	const setGradientBackground = (outletDetail = false, force = false) => {
		gsap.timeline()
			.to(outletDetail ? backgroundOutletDetail.current : backgroundOutletList.current, {
				duration: force ? 0 : transitionDuration,
				display: 'block',
				opacity: 1,
			})
			.to(outletDetail ? backgroundOutletList.current : backgroundOutletDetail.current, {
				duration: force ? 0 : transitionDuration,
				display: 'none',
				opacity: 0,
			});
	};
	// Event handler methods
	const onClickCityLocation = locationIndex =>  {
		setActiveOutlet(-1);
		setActiveLocation(locationIndex);
		setMapCenter({
			lat: locationsData[locationIndex].outlet.location.coordinates[0],
			lng: locationsData[locationIndex].outlet.location.coordinates[1],
		});
		if (!isDesktopView) { // Mobile view
			setGradientBackground(true);
			setActiveOutlet(locationsData[locationIndex]['outletIndex']);
			gsap.timeline()
				.to(outletListRef.current, {
					duration: transitionDuration,
					display: 'none',
					ease: 'none',
					opacity: 0,
				})
				.to([outletDetailsHeaderRef.current, outletDetailsContentRef.current], {
					duration: transitionDuration,
					display: 'block',
					ease: 'none',
					opacity: 1,
					x: 0,
				});
		}
	};

	const onClickOutlet = outletIndex => {
		setActiveOutlet(outletIndex);
		if (outletIndex !== activeOutlet) {
			let activeLocIndex = searchActiveLocation(outletsData[outletIndex]['city'], outletsData[outletIndex]['province']);
			setMapCenter({
				lat: outletsData[outletIndex].location.coordinates[0],
				lng: outletsData[outletIndex].location.coordinates[1],
			});
			setActiveLocation(activeLocIndex);
			gsap.timeline()
				.to(outletListRef.current, {
					duration: transitionDuration,
					display: 'none',
					ease: 'none',
					opacity: 0,
				})
				.to([outletDetailsHeaderRef.current, outletDetailsContentRef.current], {
					duration: transitionDuration,
					display: 'block',
					ease: 'none',
					opacity: 1,
				});
		}
	};

	const onClickBack = () => {
		gsap.timeline({
			onStart: () => {
				setGradientBackground();
				setActiveLocation(-1);
			},
		})
			.to([outletDetailsHeaderRef.current, outletDetailsContentRef.current], {
				duration: transitionDuration,
				display: 'none',
				ease: 'none',
				opacity: 0,
				onComplete: () => setActiveOutlet(-1),
			})
			.to(outletListRef.current, {
				duration: transitionDuration,
				display: 'block',
				ease: 'none',
				opacity: 1,
			});
	};
	// Hooks
	useEffect(() => {
		if (width) {
			if (isDesktopView) {
			} else {
				if (activeOutlet === -1) {
					setActiveLocation(-1);
				} else {
					setGradientBackground(true, true);
				}
			}
		}
	}, [isDesktopView]);
	// Render
	return (
		<div className={styles.OutletLocationMap}>
			<div className={styles.OutletLocationMap__map}>
				<GoogleMapReact
					{...mapConfigs}
					center={mapCenter}
					options={createMapOptions}
					yesIWantToUseGoogleMapApiInternals
					bootstrapURLKeys={{key: GOOGLE_MAPS_API_KEY}}
					onGoogleApiLoaded={({map, maps}) => handleOnLoad(map, maps)}>
					{outletsData.map((data, i) => {
						return (
							<OutletLocationMarker
								key={i}
								type={data.isDinein ? 'dineIn' : 'outlet'}
								label={data.label}
								active={activeOutlet === i}
								onClickMarker={() => onClickOutlet(i)}
								lat={data.location.coordinates[0]}
								lng={data.location.coordinates[1]}
							/>
						);
					})}
				</GoogleMapReact>
			</div>
			<OutletLocationMapLegend className={styles.OutletLocationMap__legend} />
			<div
				ref={backgroundOutletList}
				className={clsx(
					styles.OutletLocationMap__gradientBackgroundMobile,
					styles.OutletLocationMap__backgroundOutletList,
				)} />
			<div
				ref={backgroundOutletDetail}
				className={clsx(
					styles.OutletLocationMap__gradientBackgroundMobile,
					styles.OutletLocationMap__backgroundOutletDetail,
				)} />
			<OutletLocationList
				ref={outletListRef}
				outletsList={locationsData}
				activeLocation={activeLocation}
				setActiveLocation={onClickCityLocation}
			/>
			<OutletDetails
				onBackPress={onClickBack}
				isDesktopView={isDesktopView}
				locationsData={locationsData}
				headerRef={outletDetailsHeaderRef}
				contentRef={outletDetailsContentRef}
				activeLocationIndex={activeLocation}
				activeOutlet={[outletsData[activeOutlet]]}
			/>
		</div>
	);
};

OutletLocationMap.defaultProps = {
	outletsData: [],
	locationsData: [],
};

OutletLocationMap.propTypes = {
	outletsData: PropTypes.arrayOf(PropTypes.object),
	locationsData: PropTypes.arrayOf(PropTypes.object),
};

export default OutletLocationMap;
