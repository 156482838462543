import {useState, useEffect, useRef} from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

const MouseEventHandler = props => {
	const enableCursorHandler = process.env.NODE_ENV !== 'development';
	const [isCursorVisible, setVisibleStatus] = useState(false);
	const enableCustomCursor = useRef(props.enableCustomCursor);
	const cursorComponentRef = useRef(null);
	const CursorElement = props.cursorElement;
	let mouseX = 0, mouseY = 0, mouseXViewport = 0, mouseYViewport = 0;
	let xp = 0, yp = 0;
	// Methods
	const handleMouseMove = e => {
		if (props.containerRef) {
			const rect = props.containerRef.current.getBoundingClientRect();
			mouseX = (e.clientX || mouseXViewport) + window.scrollX;
			mouseY = (e.clientY || mouseYViewport) + window.scrollY;
			mouseXViewport = e.clientX || mouseXViewport;
			mouseYViewport = e.clientY || mouseYViewport;
			if (
				rect !== null &&
				mouseXViewport >= rect.left &&
				mouseXViewport <= rect.right &&
				mouseYViewport >= rect.top &&
				mouseYViewport <= rect.bottom
			) {
				setVisibleStatus(true);
			} else {
				setVisibleStatus(false);
			}
		}
	};
	// Hooks
	useEffect(() => {
		if (enableCursorHandler) {
			document.addEventListener('mousemove', handleMouseMove);
			document.addEventListener('scroll', handleMouseMove);
			const interval = setInterval(() => {
				if (cursorComponentRef.current) {
					const cursorDimension = cursorComponentRef.current.getBoundingClientRect();
					xp += ((mouseX - xp)/6);
					yp += ((mouseY - yp)/6);
					if (enableCustomCursor.current) {
						cursorComponentRef.current.style.left = `${xp - cursorDimension.width / 2}px`;
						cursorComponentRef.current.style.top = `${yp - cursorDimension.height / 2}px`;
					}
				}
			}, 20);
			return () => {
				document.removeEventListener('mousemove', handleMouseMove);
				document.removeEventListener('scroll', handleMouseMove);
				clearInterval(interval);
			};
		}
	}, []);
	useEffect(() => {
		if (enableCustomCursor.current !== props.enableCustomCursor) {
			if (props.enableCustomCursor) {
				cursorComponentRef.current.style.removeProperty('display');
			} else {
				cursorComponentRef.current.style.display = 'none';
			}
			enableCustomCursor.current = props.enableCustomCursor;
		}
	}, [props.enableCustomCursor]);
	// Render react portal
	return ReactDOM.createPortal(
		<CursorElement
			ref={cursorComponentRef}
			isVisible={isCursorVisible} />,
		document.getElementById('root'),
	);
};

MouseEventHandler.defaultProps = {
	cursorElement: () => null,
	enableCustomCursor: true,
};

MouseEventHandler.propTypes = {
	containerRef: PropTypes.shape({
		current: PropTypes.instanceOf(Element),
	}),
	cursorElement: PropTypes.elementType,
	enableCustomCursor: PropTypes.bool,
};

export default MouseEventHandler;
