import {useRef} from 'react';
import {useResizeDetector} from 'react-resize-detector';
import {useHistory} from 'react-router-dom';

// Global components
import Button from 'components/core/Button';

// Local styles
import styles from './partnership.module.scss';

const PartnershipDescription = () => {
	const history = useHistory();
	const {width} = useResizeDetector({
		handleHeight: false,
		targetRef: useRef(document.body),
	});
	const enableMagneticButtonEffect = width > parseInt(styles.mobileBreakPoint);
	// Render
	return (
		<div className={styles.PartnershipDescription}>
			<span className={styles.PartnershipDescription__headerText}>
				Want to be a part of our <p>outlet expansion</p>?
			</span>
			<p className={styles.PartnershipDescription__descriptionText}>
				Bring new Moon Chicken stations to your city / region together with us.
			</p>
			<Button
				label="OUTLET PARTNERSHIP"
				id="home-expansion-outlet-partnership"
				enableMagneticEffect={enableMagneticButtonEffect}
				onClick={() => history.push('/partnership')}
				className={styles.PartnershipDescription__outletPartnershipButton} />
		</div>
	);
};

export default PartnershipDescription;
