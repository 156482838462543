import {NavLink} from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// Fontawesome
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFacebookF, faInstagram, faTwitter} from '@fortawesome/free-brands-svg-icons';

// Global components
import CloudinaryImage from 'components/core/CloudinaryImage';

// Local styles
import styles from './footer.module.scss';

// Global utils
import {LINKS} from 'utils/constants';

const FooterSection = props => {
	return (
		<div className={clsx(
			styles.Footer_section,
			styles[`Footer_${props.section}`],
		)}>
			{
				props.heading &&
					<h3 className={clsx(styles.Footer_heading)}>{props.heading}</h3>
			}
			<div className={clsx(
				styles.Footer_content,
				styles[`Footer_content_${props.section}`],
			)}>
				{props.children}
			</div>
		</div>
	);
};

const SocialMedia = () => {
	const socialMedia = [
		{
			link:'https://www.instagram.com/lifeathangry/',
			icon: faInstagram,
		},
		{
			link:'https://www.facebook.com/Hangry-Career-112950770136641/',
			icon: faFacebookF,
		},
		{
			link:'https://twitter.com/hangryindonesia',
			icon: faTwitter,
		},
	];

	return (
		<div className={styles.Footer_social_icons_container}>
			{
				socialMedia.map(socmed => (
					<a
						target="_blank"
						rel="noopener noreferrer"
						href={socmed.link}
						className={styles.Footer_social_icons}
						key={socmed.link}>
						<FontAwesomeIcon icon={socmed.icon} />
					</a>
				))
			}
		</div>
	);
};

const Footer = () => {
	const downloadLinks = [
		{
			link: 'https://apps.apple.com/us/app/hangry/id1498223490',
			id: 'footer-hangry-download-ios',
			image: 'appstore',
		},
		{
			link: 'https://play.google.com/store/apps/details?id=com.modular.ishangry',
			id: 'footer-hangry-download-android',
			image: 'playstore',
		},
	];

	return (
		<div className={styles.Footer}>
			<FooterSection
				section='explore'
				heading='EXPLORE'>
				{
					LINKS.map(link => (
						<NavLink
							exact={true}
							to={link.path}
							key={link.path}
							activeClassName={styles.active}
							id={`footer-explore-${link.id}`}
							className={styles.Footer_explore_navlink}>
							{link.label}
						</NavLink>
					))
				}
			</FooterSection>

			<FooterSection
				section='social'
				heading='GET IN TOUCH'>
				<p>
					Mail: <a
						id="footer-hangry-email"
						className={styles.yellow}
						href="mailto:contact@ishangry.com">
						contact@ishangry.com
					</a>
				</p>
				<p>
					Whatsapp: <a
						id="footer-hangry-phone"
						href="tel:+628175092000"
						className={styles.yellow}>
						+62 817 509 2000
					</a>
				</p>
				<SocialMedia />
			</FooterSection>

			<FooterSection section='web'>
				<p>Part of the <span className={styles.yellow}>Hangry</span> universe</p>
				<a
					target="_blank"
					className={styles.yellow}
					rel="noopener noreferrer"
					id="footer-hangry-website"
					href="https://www.ishangry.com">
					www.ishangry.com
				</a>
				<div className={styles.Footer_copyright}>
					<p>© 2021 Moon Chicken.</p>
					<p>All Rights Reserved.</p>
				</div>
			</FooterSection>

			<FooterSection
				section='get_app'
				heading='GET HANGRY APP'>
				{
					downloadLinks.map(downlink => (
						<a
							target="_blank"
							id={downlink.id}
							key={downlink.link}
							href={downlink.link}
							rel="noopener noreferrer">
							<CloudinaryImage imageId={downlink.image} />
						</a>
					))
				}
			</FooterSection>
		</div>
	);
};

FooterSection.propTypes = {
	section: PropTypes.string,
	heading: PropTypes.string,
	contentClassName: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
};

export default Footer;
