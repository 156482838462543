import {useRef} from 'react';
import {useResizeDetector} from 'react-resize-detector';

// Global components
import Button from 'components/core/Button';

// Local components
import FlavorsContentDesktop from './FlavorsContentDesktop';
import FlavorsContentMobile from './FlavorsContentMobile';

// Global utils
import {FLAVORS} from 'utils/constants';

// Local styles
import styles from './flavorsDetail.module.scss';

const FlavorsDetail = () => {

	const bodyElementRef = useRef(document.querySelector('body'));
	const {width} = useResizeDetector({
		handleHeight: false,
		targetRef: bodyElementRef,
	});

	return (
		<div className={styles.FlavorsDetail}>
			<h1
				className={styles.FlavorsDetail__title}
				id='flavorsTitle'>
				<span>Flavors</span> of the galaxy
			</h1>
			{width > parseInt(styles.mobileBreakPoint) ?
				<FlavorsContentDesktop flavors={FLAVORS} /> :
				<FlavorsContentMobile flavors={FLAVORS} />
			}
			<Button
				className={styles.FlavorsDetail__button}
				enableMagneticEffect={width > parseInt(styles.mobileBreakPoint)}
				label='SEE OUR FULL MENU'
				id='menu-see-full-menu' />
		</div>
	);
};

export default FlavorsDetail;
