// Meteor Parallax
import {useRef, useEffect} from 'react';

// Global assets
import JPGMenuBGMoon from 'assets/images/menu-bg-moon.jpg';
import PNGMeteor7 from 'assets/images/menu-meteor-7.png';
import PNGMeteor8 from 'assets/images/menu-meteor-8.png';

// Local styles
import styles from './background.module.scss';

// Global utils
import {parallaxMeteor} from 'utils/index';

const Background = () => {
	const smallMeteor = useRef(null);
	const largeMeteor = useRef(null);
	// Hooks
	useEffect(() => {
		parallaxMeteor({
			smallMeteor: smallMeteor.current,
			largeMeteor: largeMeteor.current,
		});
	}, []);
	// Render
	return (
		<div className={styles.Background}>
			<div className={styles.Background__lines}>
				<div className={styles.Background__lines__mainLines} />
				<div className={styles.Background__lines__verticalLine} />
				<div className={styles.Background__lines__secondaryLines} />
			</div>
			<div className={styles.Background__menuBgMoon}>
				<img
					alt='Menu BG Moon'
					className={styles.Background__menuBgMoon__image}
					src={JPGMenuBGMoon} />
				<div className={styles.Background__menuBgMoon__lines} />
			</div>
			<div className={styles.Background__meteorImg}>
				<img
					alt='Menu Meteor 8'
					className={styles.Background__meteorImg__meteor8}
					src={PNGMeteor8}
					ref={smallMeteor} />
				<img
					alt='Menu Meteor 7'
					className={styles.Background__meteorImg__meteor7}
					src={PNGMeteor7}
					ref={largeMeteor} />
			</div>
		</div>
	);
};

export default Background;
