const GOOGLE_MAPS_API_KEY = 'AIzaSyCAZFCbobmfRYTh8-e4ogX-FcXKVS1COAg';
const LINKS = [
	{label: 'home', id: 'home', path: '/'},
	{label: 'menu', id: 'menu', path: '/menu'},
	{label: 'outlet location', id: 'outlet-location', path: '/outlet-location'},
	{label: 'partnership', id: 'partnership', path: '/partnership'},
];
const FLAVORS = [
	{
		title: 'Smoked paprika dry spices',
		logo: 'menu-logo-smokey',
		richnessLevel: 80,
		sweetLevel: 20,
		heatLevel: 20,
	},
	{
		title: 'The original savory goodness',
		logo: 'menu-logo-ori',
		richnessLevel: 60,
		sweetLevel: 0,
		heatLevel: 0,
	},
	{
		title: 'Korean-style sweet & spicy sauce',
		logo: 'menu-logo-bigbang',
		richnessLevel: 40,
		sweetLevel: 60,
		heatLevel: 80,
	},
	{
		title: 'Sweet & savory garlicky sauce',
		logo: 'menu-logo-honey',
		richnessLevel: 60,
		sweetLevel: 80,
		heatLevel: 0,
	},
	{
		title: 'Korean-style sweet sauce',
		logo: 'menu-logo-ganjeong',
		richnessLevel: 60,
		sweetLevel: 80,
		heatLevel: 20,
	},
	{
		title: 'Cajun-flavored dry spices',
		logo: 'menu-logo-lstar',
		richnessLevel: 80,
		sweetLevel: 60,
		heatLevel: 20,
	},
	{
		title: 'The basic flavor. No additional seasoning needed',
		logo: 'menu-logo-gzero',
		richnessLevel: 40,
		sweetLevel: 0,
		heatLevel: 0,
	},
	{
		title: 'The signature Korean Samyang sauce',
		logo: 'menu-logo-samyang',
		richnessLevel: 80,
		sweetLevel: 20,
		heatLevel: 100,
	},
];

export {
	LINKS,
	FLAVORS,
	GOOGLE_MAPS_API_KEY,
};
