import {ScrollTrigger} from 'gsap/ScrollTrigger';
import {gsap} from 'gsap';

// Global styles
import othersStyles from 'styles/_others.module.scss';

gsap.registerPlugin(ScrollTrigger);

export const pxToVw = pxValue => {
	const {screenWidthDesktop} = othersStyles;
	return (parseFloat(pxValue)  / parseFloat(screenWidthDesktop)) * 100;
};

const defaultParamsParallaxMeteor = {
	smallMeteor: null, largeMeteor: null, markers: false,
};

export const parallaxMeteor = (params = defaultParamsParallaxMeteor) => {
	params = {...defaultParamsParallaxMeteor, ...params};
	const minDesktopWidth = parseInt(othersStyles.mobileBreakPoint) + 1;
	const gsapAnimate = (element, multiplyValue = 5) => {
		const baseMultiply = 8;
		const {top} = element.getBoundingClientRect();
		const startScroll = top <= window.innerHeight ? top : window.innerHeight;
		const endScroll = top <= window.innerHeight ? `${top - window.innerHeight}px` : 'top';
		ScrollTrigger.matchMedia({
			[`(min-width: ${minDesktopWidth}px)`]: () => {
				gsap.to(element, {
					scrollTrigger: {
						start: `top ${startScroll}px`,
						end: `bottom ${endScroll}`,
						markers: params.markers,
						trigger: element,
						scrub: true,
					},
					ease: 'none',
					y: `-${pxToVw(baseMultiply * multiplyValue)}vw`,
				});
			},
		});
	};
	params?.smallMeteor && gsapAnimate(params.smallMeteor, 20);
	params?.largeMeteor && gsapAnimate(params.largeMeteor, 40);
};
