import {useRef, useEffect} from 'react';
import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';

// Local styles
import styles from './background.module.scss';

// Global assets
import PNGPartnershipMeteor1 from 'assets/images/partnership-meteor-1.png';
import PNGPartnershipMeteor2 from 'assets/images/partnership-meteor-2.png';
import PNGPartnershipMeteor3 from 'assets/images/partnership-meteor-3.png';
import PNGPartnershipMeteor4 from 'assets/images/partnership-meteor-4.png';
import JPGPartnershipMoon from 'assets/images/partnership-moon.jpg';

// Global utils
import {parallaxMeteor} from 'utils/index';

gsap.registerPlugin(ScrollTrigger);

const Background = () => {
	const smallMeteor = useRef(null);
	const largeMeteor = useRef(null);
	const secondSmallMeteor = useRef(null);
	const secondLargeMeteor = useRef(null);

	useEffect(() => {
		parallaxMeteor({
			smallMeteor: smallMeteor.current,
			largeMeteor: largeMeteor.current,
		});
		parallaxMeteor({
			smallMeteor: secondSmallMeteor.current,
			largeMeteor: secondLargeMeteor.current,
		});
	}, []);

	return (
		<div className={styles.Background}>
			<div className={styles.Background__backgroundImage}>
				<img
					alt="Meteor 2"
					src={PNGPartnershipMeteor2}
					ref={smallMeteor}
					className={styles.Background__backgroundImage__meteor2} />
				<img
					alt="Meteor 1"
					src={PNGPartnershipMeteor1}
					ref={largeMeteor}
					className={styles.Background__backgroundImage__meteor1} />

				<div className={styles.Background__backgroundImage__moon}>
					<img
						alt="Moon"
						src={JPGPartnershipMoon}
						className={styles.Background__backgroundImage__moon__img} />
				</div>
				<img
					alt="Meteor 4"
					src={PNGPartnershipMeteor4}
					ref={secondLargeMeteor}
					className={styles.Background__backgroundImage__meteor4} />
				<img
					alt="Meteor 3"
					src={PNGPartnershipMeteor3}
					ref={secondSmallMeteor}
					className={styles.Background__backgroundImage__meteor3} />
			</div>
			<div className={styles.Background__grid}>
				<div className={styles.Background__grid__center} />
				<div className={styles.Background__grid__vertical} />
			</div>
		</div>
	);
};

export default Background;