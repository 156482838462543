import {forwardRef} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// Local styles
import styles from './customCursor.module.scss';

const CustomCursor = forwardRef((props, ref) => {
	return (
		<div
			ref={ref}
			className={clsx(styles.CustomCursor, {[styles.CustomCursor__visible]: props.isVisible})}>
			<div className={styles.CustomCursor__circle}>
				<p>DRAG</p>
			</div>
		</div>
	);
});

CustomCursor.propTypes = {
	isVisible: PropTypes.bool,
};

export default CustomCursor;
