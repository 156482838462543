import {useRef, useEffect} from 'react';
import {useResizeDetector} from 'react-resize-detector';
import {Draggable} from 'gsap/Draggable';
import Inertia from 'gsap/Inertia';
import {gsap} from 'gsap';

// Local styles
import styles from './gallery.module.scss';

// Global components
import CustomCursor from 'components/others/CustomCursor';
import CloudinaryImage from 'components/core/CloudinaryImage';
import MouseEventHandler from 'components/others/MouseEventHandler';

gsap.registerPlugin(Draggable, Inertia);

const Gallery = () => {
	const bodyElementRef = useRef(document.querySelector('body'));
	const {width} = useResizeDetector({
		handleHeight: false,
		targetRef: bodyElementRef,
	});
	const containerSliderRef = useRef(null);
	const contentSliderRef = useRef(null);
	const gallery = [
		{image: 'home-about-gallery-1', label: 'HONEY GARLAXY'},
		{image: 'home-about-gallery-2', label: 'POWDER FLAVOR'},
		{image: 'home-about-gallery-3', label: 'BIG BANG'},
		{image: 'home-about-gallery-4', label: 'METEOR RICE CHICKEN'},
		{image: 'home-about-gallery-5', label: 'XL METEOR CHICKEN'},
	];
	// Hooks
	useEffect(() => {
		Draggable.create(contentSliderRef.current, {
			bounds: containerSliderRef.current,
			dragResistance: 0.35,
			cursor: 'default',
			inertia: true,
			type: 'x',
		});
	}, []);
	// Render
	return (
		<>
			{width && (
				<MouseEventHandler
					cursorElement={CustomCursor}
					containerRef={contentSliderRef}
					enableCustomCursor={width > parseInt(styles.mobileBreakPoint)} />
			)}
			<div
				ref={containerSliderRef}
				className={styles.Gallery}>
				<p className={styles.Gallery__headerText}>GALLERY</p>
				<div
					ref={contentSliderRef}
					className={styles.Gallery__contentSlider}>
					{gallery.map((each, i) => (
						<div
							key={i}
							className={styles.Gallery__eachImageItem}>
							<CloudinaryImage imageId={each.image} />
							<span className={styles.Gallery__eachImageLabel}>
								{`0${i + 1}/`}
								<p>{each.label}</p>
							</span>
						</div>
					))}
				</div>
			</div>
		</>
	);
};

export default Gallery;
