import {useRef} from 'react';
import {useResizeDetector} from 'react-resize-detector';
import {gsap} from 'gsap';

// Fontawesome
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLongArrowRight, faLongArrowLeft} from '@fortawesome/pro-light-svg-icons';

// Global components
import CloudinaryImage from 'components/core/CloudinaryImage';

// Local styles
import styles from './flavorVariant.module.scss';

const FlavorVariant = () => {
	let allowToSlide = true;
	let currentActiveIndex = 0;
	const flavors = [
		{image: 'home-hero-flavors-bigbang', label: 'BigBang', subLabel: 'Korean Chicken Wings'},
		{image: 'home-hero-flavors-gangjeong', label: 'Gangjeong', subLabel: 'Korean Chicken Wings'},
		{image: 'home-hero-flavors-honeyGarlaxy', label: 'Honey Garlaxy', subLabel: 'Korean Chicken Wings'},
		{image: 'home-hero-flavors-louisianaStar', label: 'Louisiana Star', subLabel: 'Korean Chicken Wings'},
		{image: 'home-hero-flavors-moonOriginal', label: 'Moon Original', subLabel: 'Korean Chicken Wings'},
		{image: 'home-hero-flavors-smokeyComet', label: 'Smokey Comet', subLabel: 'Korean Chicken Wings'},
	];
	const {width} = useResizeDetector({
		handleHeight: false,
		targetRef: useRef(document.querySelector('body')),
	});
	const imageContainerRef = useRef(null);
	const labelContainerRef = useRef(null);
	// Methods
	const slide = (slideType = 'left') => {
		if (allowToSlide) {
			allowToSlide = false;
			const {
				mobileBreakPoint, horizontalSlideDesktop, horizontalSlideMobile,
				verticalSlideDesktop, verticalSlideMobile,
			} = styles;
			const imageElementsArray = [...imageContainerRef.current.children];
			const labelElementsArray = [...labelContainerRef.current.children];
			const previousIndex = currentActiveIndex ? currentActiveIndex - 1 : flavors.length - 1;
			const nextIndex = currentActiveIndex === flavors.length - 1 ? 0 : currentActiveIndex + 1 ;
			const horizontalSlide = width <= parseInt(mobileBreakPoint)
				? horizontalSlideMobile
				: horizontalSlideDesktop;
			const verticalSlide = width <= parseInt(mobileBreakPoint)
				? verticalSlideMobile
				: verticalSlideDesktop;
			const newActiveIndex = slideType === 'left' ? nextIndex : previousIndex;
			gsap.killTweensOf(imageContainerRef.current.children);
			gsap.killTweensOf(labelContainerRef.current.children);
			gsap.timeline()
				.to(imageElementsArray[currentActiveIndex], {
					x: slideType === 'left' ? `-${horizontalSlide}` : horizontalSlide,
					display: 'none',
					duration: 0.5,
					ease: 'none',
					opacity: 0,
				})
				.set(imageElementsArray[newActiveIndex], {
					x: slideType === 'left' ? horizontalSlide : `-${horizontalSlide}`,
				})
				.to(imageElementsArray[newActiveIndex], {
					display: 'block',
					duration: 0.5,
					ease: 'none',
					opacity: 1,
					x: 0,
				});
			gsap.timeline({
				onComplete: () => allowToSlide = true,
			})
				.to(labelElementsArray[currentActiveIndex], {
					y: verticalSlide,
					display: 'none',
					duration: 0.5,
					ease: 'none',
					opacity: 0,
				})
				.set(labelElementsArray[newActiveIndex], {
					y: verticalSlide,
				})
				.to(labelElementsArray[newActiveIndex], {
					display: 'block',
					duration: 0.5,
					ease: 'none',
					opacity: 1,
					y: 0,
				});
			currentActiveIndex = newActiveIndex;
		}
	};
	// Render
	return (
		<div className={styles.FlavorVariant}>
			<div
				ref={imageContainerRef}
				className={styles.FlavorVariant__imageContainer}>
				{flavors.map((each, i) => {
					return (
						<CloudinaryImage
							key={i}
							imageId={each.image} />
					);
				})}
			</div>
			<div
				ref={labelContainerRef}
				className={styles.FlavorVariant__labelContainer}>
				{flavors.map((each, i) => (
					<span
						key={i}
						className={styles.FlavorVariant__labelContainer__eachLabel}>
						{each.label}
						<p>{each.subLabel}</p>
					</span>
				))}
			</div>
			<button
				onClick={() => slide('left')}
				className={styles.FlavorVariant__navigationButton}>
				<FontAwesomeIcon icon={faLongArrowRight} />
			</button>
			<button
				onClick={() => slide('right')}
				className={styles.FlavorVariant__navigationButton}>
				<FontAwesomeIcon icon={faLongArrowLeft} />
			</button>
		</div>
	);
};

export default FlavorVariant;
