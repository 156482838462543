import {useState} from 'react';
import {motion, AnimatePresence} from 'framer-motion/dist/framer-motion';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// Fontawesome
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes} from '@fortawesome/free-solid-svg-icons';

// Global utils
import {fadeInOutMotionConfig} from 'utils/animation';

// Local styles
import styles from './ticker.module.scss';

const Ticker = props => {
	const [tickerShown, setTickerVisibility] = useState(true);
	// Render
	return (
		<AnimatePresence>
			{tickerShown && (
				<motion.div
					{...fadeInOutMotionConfig}
					id="ticker-container"
					className={clsx(styles.Ticker, props.className)}>
					<div
						onClick={props.onClick}
						className={styles.Ticker__innerContainer}>
						{props.children}
					</div>
					<button
						className={styles.Ticker__closeButton}
						onClick={() => setTickerVisibility(false)}>
						<FontAwesomeIcon
							icon={faTimes}
							className={styles.Ticker__closeButton__icon} />
					</button>
				</motion.div>
			)}
		</AnimatePresence>
	);
};

Ticker.defaultProps = {
	onClick: () => null,
};

Ticker.propTypes = {
	onClick: PropTypes.func,
	children: PropTypes.node,
	className: PropTypes.string,
};

export default Ticker;
