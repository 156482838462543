import {useRef} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// Local styles
import styles from './outletLocationMarker.module.scss';

const OutletLocationMarker = props => {
	const floatingLabel = useRef(null);
	// Methods
	const customPxToVw = pxValue => {
		const viewportWidth = document.documentElement.clientWidth;
		return parseFloat(pxValue) / viewportWidth * 100;
	};
	const getFloatingLabelXCenter = () => {
		if (floatingLabel.current) {
			const halfWidth = floatingLabel.current.offsetWidth / 2;
			const markerWidth = parseFloat(styles.desktopMarkerSizePx) / 2;
			return customPxToVw(halfWidth) - customPxToVw(markerWidth);
		}
		return 0;
	};
	// Render
	return (
		<div
			className={clsx(
				styles.OutletLocationMarker,
				styles[`OutletLocationMarker__${props.type}`],
				props.active && styles[`OutletLocationMarker__active__${props.type}`],
				props.className,
			)}
			onClick={props.onClickMarker}>
			{typeof props.label === 'string' && (
				<p
					ref={floatingLabel}
					style={{
						left: `-${getFloatingLabelXCenter()}vw`,
					}}>
					{props.label}
				</p>
			)}
		</div>
	);
};

OutletLocationMarker.defaultProps = {
	active: false,
	onClickMarker: () => null,
};

OutletLocationMarker.propTypes = {
	label: PropTypes.string,
	type: PropTypes.oneOf([
		'outlet', 'dineIn',
	]),
	className: PropTypes.string,
	active: PropTypes.bool,
	onClickMarker: PropTypes.func,
};

export default OutletLocationMarker;
