import TagManager from 'react-gtm-module';

if (process.env.REACT_APP_BUILD_ENV) {
	const tagManagerArgs = {
		gtmId: 'GTM-5HPJ3JW',
		auth: process.env.REACT_APP_GTAG_AUTH,
		preview: process.env.REACT_APP_GTAG_PREVIEW,
	};
	TagManager.initialize(tagManagerArgs);
}
