import {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {gsap} from 'gsap';
import clsx from 'clsx';

// Local styles
import styles from './button.module.scss';

const Button = props => {
	let isHovered = false;
	const buttonTransitionDuration = 0.5; // In second
	const [isHoveredState, setHoverState] = useState(isHovered);
	const enableMagneticEffect = useRef(props.enableMagneticEffect);
	const buttonBackgroundRef = useRef(null);
	const buttonLabelOuterRef = useRef(null);
	const buttonLabelRef = useRef(null);
	const buttonOuterRef = useRef(null);
	const buttonRef = useRef(null);
	// Methods
	const calculateButtonPosition = (buttonDimension, magneticOffset, mouseEvent) => {
		const horizontalOffsetUnit = magneticOffset / (buttonDimension.width / 2 + magneticOffset);
		const verticalOffsetUnit = magneticOffset / (buttonDimension.height / 2 + magneticOffset);
		const horizontalCenter = buttonDimension.left + (buttonDimension.width / 2);
		const verticalCenter = buttonDimension.top + (buttonDimension.height / 2);
		return {
			horizontal: (horizontalCenter - mouseEvent.clientX) * horizontalOffsetUnit * -1,
			vertical: (verticalCenter - mouseEvent.clientY) * verticalOffsetUnit * -1,
		};
	};
	// Event handler methods
	const onMouseMoveHandler = ({clientX, clientY}) => {
		if (buttonOuterRef.current) {
			const buttonDimension = buttonOuterRef.current.getBoundingClientRect();
			const magneticOffsetX = (buttonDimension.width * props.magneticScale - buttonDimension.width) / 2;
			const magneticOffsetY = (buttonDimension.height * props.magneticScale - buttonDimension.height) / 2;
			const magneticOffset = magneticOffsetX > magneticOffsetY ? magneticOffsetY : magneticOffsetX;
			if (
				clientX >= buttonDimension.left - magneticOffset &&
				clientX <= buttonDimension.right + magneticOffset &&
				clientY >= buttonDimension.top - magneticOffset &&
				clientY <= buttonDimension.bottom + magneticOffset
			) {
				const functionParams = [buttonDimension, magneticOffset, {clientX, clientY}];
				const buttonPosition = calculateButtonPosition(...functionParams);
				// Cursor enter
				if (enableMagneticEffect.current) {
					gsap.to(buttonRef.current, {
						duration: 1,
						ease: 'Power3.easeOut',
						x: buttonPosition.horizontal,
						y: buttonPosition.vertical,
					});
					gsap.to(buttonLabelOuterRef.current, {
						duration: 1,
						ease: 'Power3.easeOut',
						x: buttonPosition.horizontal * -1 / 3,
						y: buttonPosition.vertical * -1 / 3,
					});
				}
				if (!isHovered) {
					gsap.killTweensOf(buttonBackgroundRef.current);
					gsap.killTweensOf(buttonLabelRef.current);
					gsap.to(buttonBackgroundRef.current, {
						duration: buttonTransitionDuration,
						ease: 'Power3.easeOut',
						startAt: {y: '0%'},
						y: '-75%',
					});
					gsap.timeline().to(buttonLabelRef.current, {
						duration: 0.01,
						opacity: 0,
						y: '0%',
					}).to(buttonLabelRef.current, {
						duration: buttonTransitionDuration,
						ease: 'Power3.easeOut',
						startAt: {y: '152%', opacity: 1},
						y: '0%',
					});
				}
				setHoverState(true);
				isHovered = true;
			} else {
				// Cursor leave
				if (isHovered) {
					buttonLabelOuterRef.current.style.transform = 'translate(0px, 0px)';
					gsap.killTweensOf(buttonLabelOuterRef.current);
					gsap.killTweensOf(buttonBackgroundRef.current);
					gsap.killTweensOf(buttonLabelRef.current);
					gsap.killTweensOf(buttonRef.current);
					if (enableMagneticEffect.current) {
						gsap.to(buttonRef.current, {
							duration: buttonTransitionDuration,
							ease: 'Power3.easeOut',
							x: 0,
							y: 0,
						});
					}
					gsap.to(buttonBackgroundRef.current, {
						duration: buttonTransitionDuration,
						ease: 'Power3.easeOut',
						startAt: {y: '-75%'},
						y: '-152%',
					});
					gsap.timeline().to(buttonLabelRef.current, {
						duration: 0.01,
						opacity: 0,
						y: '0%',
					}).to(buttonLabelRef.current, {
						duration: buttonTransitionDuration,
						ease: 'Power3.easeOut',
						startAt: {y: '-150%', opacity: 1},
						y: '0%',
					});
				}
				setHoverState(false);
				isHovered = false;
			}
		}
	};
	// Hooks
	useEffect(() => {
		document.addEventListener('mousemove', onMouseMoveHandler);
		return () => {
			document.removeEventListener('mousemove', onMouseMoveHandler);
		};
	}, []);
	useEffect(() => {
		if (props.enableMagneticEffect !== enableMagneticEffect.current) {
			enableMagneticEffect.current = props.enableMagneticEffect;
		}
	}, [props.enableMagneticEffect]);
	// Render
	return (
		<div
			ref={buttonOuterRef}
			className={clsx(styles.Button__outer, props.className)}>
			<button
				id={props.id}
				ref={buttonRef}
				onClick={props.onClick}
				className={clsx(
					styles.Button__inner,
					{[styles.Button__inner__hover]: isHoveredState},
				)}>
				<div
					ref={buttonBackgroundRef}
					className={styles.Button__inner__innerCircle} />
				<div ref={buttonLabelOuterRef}>
					<span
						ref={buttonLabelRef}
						className={styles.Button__inner__label}>
						{props.label}
					</span>
				</div>
			</button>
		</div>
	);
};

Button.defaultProps = {
	enableMagneticEffect: true,
	magneticScale: 1.8,
	onClick: () => null,
};

Button.propTypes = {
	enableMagneticEffect: PropTypes.bool,
	magneticScale: PropTypes.number,
	className: PropTypes.string,
	label: PropTypes.string,
	onClick: PropTypes.func,
	id: PropTypes.string,
};

export default Button;
