// Local styles
import styles from './description.module.scss';

// Local components
import PartnershipForm from './components/PartnershipForm';
import PartnershipInfo from './components/PartnershipInfo';
import PartnershipMoreInfo from './components/PartnershipMoreInfo';

const Description = () => {
	return (
		<div className={styles.Description}>
			<PartnershipInfo />
			<PartnershipForm />
			<PartnershipMoreInfo />
		</div>
	);
};

export default Description;