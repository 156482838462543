import {useRef, useState, useEffect} from 'react';
import {useResizeDetector} from 'react-resize-detector';
import {NavLink, Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// Local components
import Menu from './Menu';

// Local styles
import styles from './header.module.scss';

// Global utils
import {LINKS} from 'utils/constants';

const Header = props => {
	let scrolledPassMinimum = false;
	const minimumScrollTopValue = 8; // In PX
	const menuContainerTransDur = parseInt(styles.menuContainerTransitionDuration) / 1000;
	const bodyElementRef = useRef(document.querySelector('body'));
	const [isMenuActive, setMenuActiveState] = useState(false);
	const [headerScrolled, setScrollState] = useState(false);
	const screenWidth = useRef(bodyElementRef.current.clientWidth);
	const hamburgerMiddleLine = useRef(null);
	const headerContainerRef = useRef(null);
	const headerMenuRef = useRef(null);
	const navHeaderRef = useRef(null);
	const {width} = useResizeDetector({
		handleHeight: false,
		targetRef: bodyElementRef,
	});
	// Event handler methods
	const onForceCloseMenu = () => {
		setMenuActiveState(false);
		setScrollState(false);
	};
	const onScroll = () => {
		if (screenWidth.current <= parseInt(styles.mobileBreakPoint)) {
			const isMinimumScroll = document.documentElement.scrollTop > minimumScrollTopValue;
			if (
				(isMinimumScroll && !scrolledPassMinimum) ||
				(!isMinimumScroll && scrolledPassMinimum)
			) {
				navHeaderRef.current.style.transitionDuration = styles.scrollingBackgroundDuration;
				setTimeout(() => {
					navHeaderRef.current.removeAttribute('style');
				}, parseInt(styles.scrollingBackgroundDuration));
			}
			scrolledPassMinimum = isMinimumScroll;
			setScrollState(isMinimumScroll);
		}
	};
	const onPressHamburgerMenu = (callback = () => null) => {
		if (isMenuActive) {
			// Deactivate Menu
			headerMenuRef.current?.close(responseType => {
				const isMinimumScroll = document.documentElement.scrollTop <= minimumScrollTopValue;
				if (responseType === 'onStartClosingContainer') {
					headerContainerRef.current.removeAttribute('style');
					if (isMinimumScroll) {
						setScrollState(false);
					}
				}
				typeof callback === 'function' && callback(responseType);
			});
		} else {
			// Activate Menu
			headerContainerRef.current.style.height = '100vh';
			setScrollState(true);
			headerMenuRef.current?.open();
		}
		setMenuActiveState(!isMenuActive);
	};
	// Hooks
	useEffect(() => {
		document.addEventListener('scroll', onScroll);
		return () => document.removeEventListener('scroll', onScroll);
	}, []);
	useEffect(() => {
		screenWidth.current = width;
	}, [width]);
	// Render
	return (
		<div
			ref={headerContainerRef}
			className={styles.HeaderContainer}>
			<nav
				ref={navHeaderRef}
				className={clsx(styles.Header, props.className, {
					[styles.Header__scrolled]: headerScrolled,
				})}>
				<div className={styles.Header__inner}>
					<Link to="/">
						<img
							className={styles.Header__logo}
							src='https://res.cloudinary.com/dgsgylfvr/image/upload/c_scale,w_256/v1628572409/hangry-assets/brands/logo/Moon_Chicken_FA-02.png'
							alt="Logo"
						/>
					</Link>
					<div className={styles.Header__menu}>
						{
							LINKS.map(link => (
								<NavLink
									exact={true}
									to={link.path}
									key={link.path}
									id={`header-bar-${link.id}`}
									className={styles.Header__menu__navlink}
									activeClassName={styles['Header__menu--active']}>
									{link.label}
								</NavLink>
							))
						}
					</div>
					<button
						onClick={onPressHamburgerMenu}
						className={clsx(
							styles.Header__hamburgerButton,
							{[styles['Header__hamburgerButton--active']]: isMenuActive},
						)}>
						<div ref={hamburgerMiddleLine} />
					</button>
				</div>
				<Menu
					links={LINKS}
					ref={headerMenuRef}
					onForceClose={onForceCloseMenu}
					closeMenu={onPressHamburgerMenu}
					containerTransitionDuration={menuContainerTransDur} />
			</nav>
			<div
				onClick={onPressHamburgerMenu}
				className={styles.HeaderContainer__backdrop} />
		</div>
	);
};

Header.propTypes = {
	className: PropTypes.string,
};

export default Header;
