import clsx from 'clsx';
import PropTypes from 'prop-types';

// Local styles
import styles from './zoomControl.module.scss';

const ZoomControl = props => {
	const handleClickZoomIn = map => {
		if (map.getZoom() < 17) {
			map.setZoom(map.getZoom() + 1);
		}
	};

	const handleClickZoomOut = map => {
		if (map.getZoom() > 5) {
			map.setZoom(map.getZoom() - 1);
		}
	};

	return (
		<>
			<div
				className={clsx(
					styles.ZoomButton,
					styles.ZoomButton__in,
				)}
				onClick={() => handleClickZoomIn(props.map)} />
			<div
				className={clsx(
					styles.ZoomButton,
					styles.ZoomButton__out,
				)}
				onClick={() => handleClickZoomOut(props.map)} />
		</>
	);
};

ZoomControl.defaultProps = {
	map: {},
};

ZoomControl.propTypes = {
	map: PropTypes.object,
};

export default ZoomControl;