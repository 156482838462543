// Local styles
import styles from './achievements.module.scss';

const Achievements = () => {
	const achievements = [
		() => (
			<>
				<p className={styles.Achievements__eachAchievement__innerContainer__highlightText}>Fastest growing</p>
				chicken brand in Indonesia
			</>
		),
		() => (
			<>
				<p className={styles.Achievements__eachAchievement__innerContainer__highlightText}>80+</p>
				outlets across Jabodetabek and Bandung
			</>
		),
		() => (
			<>
				<p className={styles.Achievements__eachAchievement__innerContainer__highlightText}>100+</p>
				media publications since our launch in 2020
			</>
		),
	];
	// Render
	return (
		<div className={styles.Achievements}>
			<p className={styles.Achievements__headerTitle}>ACHIEVEMENTS</p>
			{achievements.map((each, i) => (
				<div
					key={i}
					className={styles.Achievements__eachAchievement}>
					<div className={styles.Achievements__eachAchievement__innerContainer}>
						<span className={styles.Achievements__eachAchievement__innerContainer__achievementIndex}>
							{`0${i + 1} /`}
						</span>
						<span>{each()}</span>
					</div>
				</div>
			))}
		</div>
	);
};

export default Achievements;
