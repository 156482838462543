import {useRef, useEffect} from 'react';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import {gsap} from 'gsap';

// Local styles
import styles from './background.module.scss';

// Global assets
import JPGHomeAboutMoon from 'assets/images/home-about-moon.jpg';
import JPGHomeAboutPlanet from 'assets/images/home-about-planet.jpg';
import PNGHomeAboutMeteorSmall from 'assets/images/home-about-meteor-small.png';
import PNGHomeAboutMeteorLarge from 'assets/images/home-about-meteor-large.png';

// Global utils
import {parallaxMeteor} from 'utils/index';

gsap.registerPlugin(ScrollTrigger);

const Background = () => {
	const moonSurfaceContainerRef = useRef(null);
	const moonSurfaceImageRef = useRef(null);
	const smallMeteor = useRef(null);
	const largeMeteor = useRef(null);
	// Methods
	const parallaxMoonSurface = () => {
		const minDesktopWidth = parseInt(styles.mobileBreakPoint) + 1;
		ScrollTrigger.matchMedia({
			[`(min-width: ${minDesktopWidth}px)`]: () => {
				gsap.to(moonSurfaceImageRef.current, {
					scrollTrigger: {
						trigger: moonSurfaceContainerRef.current,
						start: 'top bottom',
						end: 'bottom top',
						markers: false,
						scrub: true,
					},
					ease: 'none',
					y: '36vw',
				});
			},
		});
	};
	// Hooks
	useEffect(() => {
		parallaxMoonSurface();
		parallaxMeteor({
			smallMeteor: smallMeteor.current,
			largeMeteor: largeMeteor.current,
		});
		return () => {
			gsap.killTweensOf(moonSurfaceImageRef.current);
		};
	}, []);
	// Render
	return (
		<div className={styles.Background}>
			<img
				ref={smallMeteor}
				alt="Small Meteor"
				src={PNGHomeAboutMeteorSmall}
				className={styles.Background__smallMeteorTop} />
			<img
				ref={largeMeteor}
				alt="Large Meteor"
				src={PNGHomeAboutMeteorLarge}
				className={styles.Background__largeMeteorTop} />
			<div className={styles.Background__planet}>
				<img
					alt="Planet"
					src={JPGHomeAboutPlanet} />
			</div>
			<div
				ref={moonSurfaceContainerRef}
				className={styles.Background__moonSurface}>
				<img
					alt="Moon Surface"
					src={JPGHomeAboutMoon}
					ref={moonSurfaceImageRef} />
			</div>
			<div className={styles.Background__gridBackground}>
				<div className={styles.Background__gridBackground__verticalMainLine} />
				<div className={styles.Background__gridBackground__verticalLine} />
				<div className={styles.Background__gridBackground__horizontalLine} />
			</div>
		</div>
	);
};

export default Background;
