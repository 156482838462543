// Local style
import styles from './partnershipDetails.module.scss';

// Local components
import Background from './components/Background';
import Description from './components/Description';

const PartnershipDetails = () => {
	return (
		<div className={styles.PartnershipDetails}>
			<Background />
			<Description />
		</div>
	);
};

export default PartnershipDetails;
