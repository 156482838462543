import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {gsap} from 'gsap';

// Global components
import SplashScreen from 'components/others/SplashScreen';

// Local styles
import styles from './preload.module.scss';

const PreloadScreen = props => {
	let gsapTransitionDuration = 0.4;
	const percentageProgressDuration = 1500;
	const containerRef = useRef(null);
	const splashScreenRef = useRef(null);
	const isSplashScreenShowed = window.splashScreenShowed || false;
	const [splashScreenState, setSplashScreenState] = useState(isSplashScreenShowed);
	// Methods
	const fadeInPage = () => {
		const [html] = document.getElementsByTagName('html');
		const transBetweenPageDuration = gsapTransitionDuration * 2;
		gsap.fromTo(containerRef.current, {
			display: 'none',
			opacity: 0,
		}, {
			duration: splashScreenState ? transBetweenPageDuration : gsapTransitionDuration,
			delay: splashScreenState ? 0.1 : 0,
			display: 'block',
			opacity: 1,
			onComplete: () => {
				html.style.removeProperty('overflow');
				props.onCompleteFadeIn();
			},
		});
	};
	// Hooks
	useEffect(() => {
		if (splashScreenState) {
			fadeInPage();
		} else {
			const [html] = document.getElementsByTagName('html');
			html.style.overflow = 'hidden';
			setTimeout(() => {
				const tl = gsap.timeline();
				tl.set(containerRef.current, {
					display: 'none',
					opacity: 0,
				});
				tl.to(splashScreenRef.current, {
					duration: gsapTransitionDuration,
					display: 'none',
					opacity: 0,
					onComplete: () => {
						window.splashScreenShowed = true;
						setSplashScreenState(true);
						fadeInPage();
					},
				});
			}, percentageProgressDuration + 250);
		}
	}, []);
	// Render
	return (
		<>
			{!splashScreenState && (
				<SplashScreen
					ref={splashScreenRef}
					className={styles.Splashscreen}
					percentageProgressDuration={percentageProgressDuration} />
			)}
			<div
				ref={containerRef}
				className={props.className}>
				{props.children}
			</div>
		</>
	);
};

PreloadScreen.defaultProps = {
	onCompleteFadeIn: () => null,
};

PreloadScreen.propTypes = {
	children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
	className: PropTypes.string,
	onCompleteFadeIn: PropTypes.func,
};

export default PreloadScreen;
