// Global components
import CloudinaryImage from 'components/core/CloudinaryImage';

// Local styles
import styles from './bestSellersDetail.module.scss';

const BestSellersDetail = () => {
	const menus = [
		{text: 'KOREAN CHICKEN WINGS', image: 'menu-bs-ganjeong', id:'wings'},
		{text: 'Moon Fried Chicken', image: 'menu-bs-honeyGarlaxy', id:'fried'},
		{text: 'XL Meteor Chicken', image: 'menu-bs-smokeyComet', id:'meteorChicken'},
	];
	// Render
	return (
		<div className={styles.BestSellersDetail}>
			<p className={styles.BestSellersDetail__headingText}>
				<span>Bestsellers</span> from <br />The Moon Chicken Universe
			</p>
			<div className={styles.BestSellersDetail__content}>
				{menus.map(menu => {
					return (
						<div
							className={styles.BestSellersDetail__content__menu}
							key={menu.id}
							id={menu.id} >
							<div className={styles.BestSellersDetail__content__menu__image}>
								<div className={styles.BestSellersDetail__content__menu__image__shadow} />
								<CloudinaryImage imageId={menu.image} />
							</div>
							<p className={styles.BestSellersDetail__content__menu__text}>
								{menu.text}
							</p>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default BestSellersDetail;
