import {useRef} from 'react';
import {useResizeDetector} from 'react-resize-detector';

// Local styles
import styles from './moreInfo.module.scss';

// Global components
import Button from 'components/core/Button';

const PartnershipMoreInfo = () => {
	const bodyElementRef = useRef(document.querySelector('body'));
	const {width} = useResizeDetector({
		handleHeight: false,
		targetRef: bodyElementRef,
	});
	const enableMagneticButtonEffect = width > parseInt(styles.mobileBreakPoint);

	return (
		<div className={styles.PartnershipMoreInfo}>
			<p className={styles.PartnershipMoreInfo__text}>
				In the meantime, Moon Chicken will still be opening new delivery-only
				outlets as part of the Hangry group.
			</p>
			<Button
				label="FIND OUT MORE HERE"
				id="partnership-more-info-button"
				enableMagneticEffect={enableMagneticButtonEffect} />
		</div>
	);
};

export default PartnershipMoreInfo;
