import {useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// Local styles
import styles from './textfield.module.scss';

const TextField = ({label, onChange, className, isRequired, ...fieldProps}) => {
	const [textFieldValue, setTextFieldValue] = useState('');
	// Methods
	const handleChange = event => {
		setTextFieldValue(event.target.value);
		onChange(event.target.value);
	};
	// Render
	return (
		<div className={clsx(
			styles.TextField,
			className,
		)}>
			<div className={clsx(styles.TextField__label)}>
				{
					label && (
						<label
							htmlFor={fieldProps.id}
							className={clsx(styles.TextField__label__name)}>
							{label}
						</label>
					)
				}
				{
					!isRequired && (
						<p className={clsx(styles.TextField__label__optional)}>(OPTIONAL)</p>
					)
				}
			</div>
			{
				fieldProps.type === 'textarea' ? (
					<textarea
						className={clsx(
							styles.TextField__field,
							styles.TextField__field__textArea,
							{[styles.TextField__field__filled]: textFieldValue !== ''},
						)}
						onChange={handleChange}
						{...fieldProps}
					/>
				) : (
					<input
						className={clsx(
							styles.TextField__field,
							styles.TextField__field__input,
							{[styles.TextField__field__filled]: textFieldValue !== ''},
						)}
						onChange={handleChange}
						{...fieldProps}
					/>
				)
			}
		</div>
	);
};

TextField.defaultProps = {
	onChange: () => null,
	fieldProps: {type: 'text'},
	isRequired: false,
};

TextField.propTypes = {
	label: PropTypes.string,
	onChange: PropTypes.func,
	className: PropTypes.string,
	fieldProps: PropTypes.object,
	isRequired: PropTypes.bool,
};

export default TextField;
