// Local styles
import styles from './outletExpansion.module.scss';

// Local components
import Background from './Background';
import PartnershipDescription from './PartnershipDescription';

const OutletExpansion = () => {
	return (
		<div className={styles.OutletExpansion}>
			<Background />
			<PartnershipDescription />
		</div>
	);
};

export default OutletExpansion;
