import {forwardRef} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// Local styles
import styles from './splashScreen.module.scss';

// Local components
import Background from './Background';
import Content from './Content';

const SplashScreen = forwardRef((props, ref) => {
	// Render
	return (
		<div
			ref={ref}
			className={clsx(styles.SplashScreen, props.className)}>
			<Background percentageProgressDuration={props.percentageProgressDuration} />
			<Content percentageProgressDuration={props.percentageProgressDuration} />
		</div>
	);
});

SplashScreen.defaultProps = {
	percentageProgressDuration: 100,
};

SplashScreen.propTypes = {
	className: PropTypes.string,
	percentageProgressDuration: PropTypes.number,
};

export default SplashScreen;
