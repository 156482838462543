// Local components
import Background from './Background';
import BestSellersDetail from './BestSellersDetail';

// Local styles
import styles from './bestSellers.module.scss';

const BestSellers = () => {
	return (
		<div className={styles.BestSellers}>
			<Background />
			<BestSellersDetail />
		</div>
	);
};

export default BestSellers;
