export default
[
	{
		'featureType': 'all',
		'elementType': 'labels.text.fill',
		'stylers': [
			{
				'visibility': 'off',
			},
			{
				'saturation': 36,
			},
			{
				'color': '#10161D',
			},
			{
				'lightness': 40,
			},
		],
	},
	{
		'featureType': 'all',
		'elementType': 'labels.text.stroke',
		'stylers': [
			{
				'visibility': 'off',
			},
			{
				'color': '#10161D',
			},
			{
				'lightness': 16,
			},
		],
	},
	{
		'featureType': 'all',
		'elementType': 'labels.icon',
		'stylers': [
			{
				'visibility': 'off',
			},
		],
	},
	{
		'featureType': 'administrative',
		'elementType': 'geometry.fill',
		'stylers': [
			{
				'color': '#10161D',
			},
			{
				'lightness': 0,
			},
		],
	},
	{
		'featureType': 'administrative',
		'elementType': 'geometry.stroke',
		'stylers': [
			{
				'color': '#10161D',
			},
			{
				'lightness': 8,
			},
			{
				'weight': 1.2,
			},
		],
	},
	{
		'featureType': 'landscape',
		'elementType': 'geometry',
		'stylers': [
			{
				'color': '#10161D',
			},
			{
				'lightness': 0,
			},
		],
	},
	{
		'featureType': 'poi',
		'elementType': 'geometry',
		'stylers': [
			{
				'color': '#10161D',
			},
			{
				'lightness': 10,
			},
		],
	},
	{
		'featureType': 'road.highway',
		'elementType': 'geometry.fill',
		'stylers': [
			{
				'color': '#10161D',
			},
			{
				'lightness': 8,
			},
		],
	},
	{
		'featureType': 'road.highway',
		'elementType': 'geometry.stroke',
		'stylers': [
			{
				'color': '#10161D',
			},
			{
				'lightness': 8,
			},
			{
				'weight': 0.2,
			},
		],
	},
	{
		'featureType': 'road.arterial',
		'elementType': 'geometry',
		'stylers': [
			{
				'color': '#10161D',
			},
			{
				'lightness': 9,
			},
		],
	},
	{
		'featureType': 'road.local',
		'elementType': 'geometry',
		'stylers': [
			{
				'color': '#10161D',
			},
			{
				'lightness': 8,
			},
		],
	},
	{
		'featureType': 'transit',
		'elementType': 'geometry',
		'stylers': [
			{
				'color': '#10161D',
			},
			{
				'lightness': 10,
			},
		],
	},
	{
		'featureType': 'water',
		'elementType': 'geometry',
		'stylers': [
			{
				'color': '#060A0D',
			},
			{
				'lightness': 0,
			},
		],
	},
];