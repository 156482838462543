import {useRef, useEffect} from 'react';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import {gsap} from 'gsap';

// Local styles
import styles from './background.module.scss';

// Global components
import CloudinaryImage from 'components/core/CloudinaryImage';

// Global assets
import SVGHomeHeroLine from 'assets/vectors/home-hero-line.svg';
import PNGHomeHeroMeteorSmall from 'assets/images/home-hero-meteor-small.png';
import PNGHomeHeroMeteorLarge from 'assets/images/home-hero-meteor-large.png';

// Global utils
import {parallaxMeteor} from 'utils/index';

gsap.registerPlugin(ScrollTrigger);

const Background = () => {
	const smallMeteor = useRef(null);
	const largeMeteor = useRef(null);
	// Hooks
	useEffect(() => {
		parallaxMeteor({
			smallMeteor: smallMeteor.current,
			largeMeteor: largeMeteor.current,
		});
	}, []);
	// Render
	return (
		<div className={styles.Hero__background}>
			<div className={styles.Hero__background__backgroundImage}>
				<CloudinaryImage
					imageId="home-hero-planet"
					className={styles.Hero__background__backgroundImage__planet} />
				<CloudinaryImage
					imageId="home-hero-planet-texture"
					className={styles.Hero__background__backgroundImage__planetTexture} />
			</div>
			<div className={styles.Hero__background__filter}>
				<img
					alt="Line"
					src={SVGHomeHeroLine}
					className={styles.Hero__background__filter__gridLine} />
				<div className={styles.Hero__background__filter__gradientFilterOverlay} />
			</div>
			<div className={styles.Hero__background__meteor} >
				<img
					ref={smallMeteor}
					alt="Meteor Small"
					src={PNGHomeHeroMeteorSmall} />
				<img
					ref={largeMeteor}
					alt="Meteor Large"
					src={PNGHomeHeroMeteorLarge} />
			</div>
		</div>
	);
};

export default Background;
