import {useRef, useEffect} from 'react';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import PropTypes from 'prop-types';
import {gsap} from 'gsap';
import clsx from 'clsx';

// Local styles
import styles from './style.module.scss';

gsap.registerPlugin(ScrollTrigger);

const ScrollIndicator = props => {
	const scrollIndicatorRef = useRef(null);
	// Hooks
	useEffect(() => {
		gsap.to(scrollIndicatorRef.current, {
			scrollTrigger: {
				trigger: scrollIndicatorRef.current,
				end: 'bottom top',
				start: 'top top',
				markers: false,
				scrub: true,
			},
			opacity: 0,
		});
	}, []);
	// Render
	return (
		<div
			ref={scrollIndicatorRef}
			className={clsx(styles.ScrollIndicator, props.className)}>
			<div className={styles.ScrollIndicator__OuterCircle}>SCROLL</div>
		</div>
	);
};

ScrollIndicator.propTypes = {
	className: PropTypes.string,
};

export default ScrollIndicator;
