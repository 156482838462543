import {useRef} from 'react';
import {useResizeDetector} from 'react-resize-detector';
import {useHistory} from 'react-router-dom';

// Global components
import Button from 'components/core/Button';

// Local styles
import styles from './outletExpansion.module.scss';

const OutletExpansion = () => {
	const bodyElementRef = useRef(document.querySelector('body'));
	const {width} = useResizeDetector({
		handleHeight: false,
		targetRef: bodyElementRef,
	});
	const isDesktop = width > parseInt(styles.mobileBreakPoint);
	const history = useHistory();
	// Render
	return (
		<div className={styles.OutletExpansion}>
			<h1 className={styles.OutletExpansion__title}>
				Want to <span>be a part of</span> our outlet expansion?
			</h1>
			<Button
				label="JOIN US NOW"
				id="outlet-expansion-join-us"
				enableMagneticEffect={isDesktop}
				className={styles.OutletExpansion__button}
				onClick={() => history.push('partnership')} />
		</div>
	);
};

export default OutletExpansion;
