import PropTypes from 'prop-types';
import {AdvancedImage} from '@cloudinary/react';
import {Cloudinary} from '@cloudinary/url-gen';
import clsx from 'clsx';

// Local styles
import styles from './cloudinaryImage.module.scss';

const CloudinaryImage = ({imageId, className}) => {
	const cloud = new Cloudinary({
		cloud: {
			cloudName: process.env.REACT_APP_CLOUDINARY,
		},
	});
	const image = cloud.image(`moon-chicken-website/${imageId}`);
	image.format('auto');
	// Render
	return typeof className === 'string' ? (
		<div className={clsx(styles.CloudinaryImage, className)}>
			<AdvancedImage cldImg={image} />
		</div>
	) : <AdvancedImage cldImg={image} />;
};

CloudinaryImage.propTypes = {
	imageId: PropTypes.string,
	className: PropTypes.string,
};

export default CloudinaryImage;
