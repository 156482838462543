import {BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom';

// Global components
import ScrollToTop from 'components/core/ScrollToTop';

// Screens
import Home from 'views/Home';
import Menu from 'views/Menu';
import Partnership from 'views/Partnership';
import OutletLocation from 'views/OutletLocation';

window.onbeforeunload = () => {
	window.scrollTo(0, 0);
};

const App = () => {
	// Render
	return (
		<Router>
			<ScrollToTop />
			<Switch>
				{/* Available path */}
				<Route
					exact
					path='/' >
					<Home />
				</Route>
				<Route path='/menu' >
					<Menu />
				</Route>
				<Route path='/partnership' >
					<Partnership />
				</Route>
				<Route path="/outlet-location">
					<OutletLocation />
				</Route>
				{/* Invalid path (redirect to home) */}
				<Redirect to='/' />
			</Switch>
		</Router>
	);
};

export default App;
