import {useState, useEffect} from 'react';
import axios from 'axios';

// Local styles
import styles from './outletLocationContent.module.scss';

// Local components
import Background from './Background';
import OutletExpansion from './OutletExpansion';
import OutletLocationMap from './OutletLocationMap';

const OutletLocationContent = () => {
	const [outletsData, setOutletsData] = useState([]);
	const [locationsData, setLocationsData] = useState([]);
	// Methods
	const fetchOutletsLocation = async () => {
		try {
			const outletLocationApiUrl = process.env.NODE_ENV === 'development'
				? 'https://api-dev.ishangry.com/order/api/v1/outlets/moon_chicken'
				: 'https://api.ishangry.com/order/api/v1/outlets/moon_chicken';
			const {data} = await axios.get(outletLocationApiUrl);
			setOutletsData(data.results);
			setLocationsData(extractLocations(data.results));
		} catch (e) {
			// DO NOTHING
		}
	};
	const extractLocations = data => {
		let locations = [];

		data.map((item, i) => {
			let city = '';
			let cityDetails = {};

			if (item.province.toLowerCase() === 'jakarta') {
				city = 'jakarta';
			} else {
				city = item.city.toLowerCase();
			}

			if (city === 'bekasi' || city === 'cikarang') {
				city = 'bekasi & cikarang';
			}

			if (!(locations.some(obj => obj.city === city))) {
				cityDetails.city = city;
				cityDetails.outlet = item;
				cityDetails.outletIndex = i;
				locations.push(cityDetails);
			}
		});
		return locations;
	};

	useEffect(() => {
		fetchOutletsLocation();
	}, []);

	return (
		<div className={styles.OutletLocationContent}>
			<Background />
			<OutletLocationMap
				outletsData={outletsData}
				locationsData={locationsData} />
			<OutletExpansion />
		</div>
	);
};

export default OutletLocationContent;
