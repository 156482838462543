import {ReactTitle} from 'react-meta-tags';

// Global components
import Header from 'components/others/Header';
import Footer from 'components/others/Footer';
import PreloadScreen from 'components/others/PreloadScreen';

// Local components
import Hero from './Hero';
import PartnershipDetails from './PartnershipDetails';

// Local styles
import styles from './partnership.module.scss';

const Partnership = () => {
	// Render
	return (
		<>
			<ReactTitle title="Partnership - Moon Chicken" />
			<PreloadScreen
				className={styles.Partnership}>
				<Header className={styles.Partnership__header} />
				<Hero />
				<PartnershipDetails />
				<Footer />
			</PreloadScreen>
		</>
	);
};

export default Partnership;
