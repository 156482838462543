// Local styles
import styles from './info.module.scss';

const PartnershipInfo = () => {
	return (
		<div className={styles.PartnershipInfo}>
			<h1 className={styles.PartnershipInfo__title}>Partnership Details</h1>
			<p className={styles.PartnershipInfo__content}>
				At this point in time, we are holding our expansion
				plans for new Moon Chicken dine-in outlets due to the pandemic.</p>
			<p className={styles.PartnershipInfo__content}>
				Sign up now to be informed earlier when we open this opportunity in the future
			</p>
		</div>
	);
};

export default PartnershipInfo;