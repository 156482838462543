import {ReactTitle} from 'react-meta-tags';

// Global components
import Header from 'components/others/Header';
import Footer from 'components/others/Footer';
import PreloadScreen from 'components/others/PreloadScreen';

// Local components
import Hero from './Hero';
import BestSellers from './BestSellers';
import Flavors from './Flavors';

// Local style
import styles from './menu.module.scss';

const Menu = () => {
	// Render
	return (
		<>
			<ReactTitle title="Menu - Moon Chicken" />
			<PreloadScreen className={styles.Menu}>
				<Header className={styles.Menu__header} />
				<Hero />
				<BestSellers />
				<Flavors />
				<Footer />
			</PreloadScreen>
		</>
	);
};

export default Menu;
