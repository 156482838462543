import {useEffect, useRef} from 'react';
import DrawSVGPlugin from 'gsap/DrawSVGPlugin';
import PropTypes from 'prop-types';
import {gsap} from 'gsap';

gsap.registerPlugin(DrawSVGPlugin);

// Global assets
import SVGLineHero from 'assets/vectors/splash-screen-line-hero.svg';

// Local styles
import styles from './background.module.scss';

const Background = props => {
	const orbitProgressRef = useRef(null);
	// Hooks
	useEffect(() => {
		gsap.fromTo(orbitProgressRef.current, {
			drawSVG: '-25% -25%',
		}, {
			duration: props.percentageProgressDuration / 1000,
			drawSVG: '-25% 75%',
			ease: 'none',
		});
	}, []);
	// Render
	return (
		<div className={styles.Background}>
			<div className={styles.Background__bottomFilter} />
			<div className={styles.Background__orbitContainer}>
				<svg
					version="1.1"
					viewBox="0 0 560 190"
					xmlns="http://www.w3.org/2000/svg"
					className={styles.Background__orbitContainer__outerOrbit}>
					<g
						id="Page-1"
						stroke="none"
						fill="none"
						fillRule="evenodd"
						strokeDasharray="5">
						<g
							id="Splash-Screen-1"
							transform="translate(-366.000000, -303.000000)">
							<g
								id="Group"
								transform="translate(359.134312, 282.942675)">
								<path
									d="M286.865688,206.057325 C441.505418,206.057325 566.865688,165.315238 566.865688,115.057325 C566.865688,64.7994133 441.505418,24.0573255 286.865688,24.0573255 C132.225958,24.0573255 6.86568806,64.7994133 6.86568806,115.057325 C6.86568806,165.315238 132.225958,206.057325 286.865688,206.057325 Z"
									id="Oval"
									transform="translate(286.865688, 115.057325) rotate(5.000000) translate(-286.865688, -115.057325) " />
							</g>
						</g>
					</g>
				</svg>
				<svg
					version="1.1"
					viewBox="0 0 400 136"
					xmlns="http://www.w3.org/2000/svg"
					className={styles.Background__orbitContainer__innerOrbit}>
					<g
						id="Page-1"
						fill="none"
						stroke="none"
						fillRule="evenodd">
						<g
							id="Splash-Screen-1"
							transform="translate(-446.000000, -330.000000)">
							<g
								id="Group"
								transform="translate(359.134312, 282.942675)">
								<ellipse
									ry="65"
									rx="200"
									id="Oval"
									cx="286.865688"
									cy="115.057325"
									ref={orbitProgressRef}
									transform="translate(286.865688, 115.057325) rotate(5.000000) translate(-286.865688, -115.057325)" />
								<ellipse
									ry="65"
									rx="200"
									id="Oval"
									cx="286.865688"
									cy="115.057325"
									className={styles.Background__orbitContainer__innerOrbit__orbitShadow}
									transform="translate(286.865688, 115.057325) rotate(5.000000) translate(-286.865688, -115.057325)" />
							</g>
						</g>
					</g>
				</svg>
			</div>
			<img
				alt="Planet"
				src={SVGLineHero}
				className={styles.Background__lineHero}  />
			<div className={styles.Background__sideFilter} />
		</div>
	);
};

Background.defaultProps = {
	percentageProgressDuration: 100,
};

Background.propTypes = {
	percentageProgressDuration: PropTypes.number,
};

export default Background;
