import {useState, useRef} from 'react';
import {useResizeDetector} from 'react-resize-detector';

// Local styles
import styles from './form.module.scss';

// Global components
import TextField from 'components/core/TextField';
import Button from 'components/core/Button';

const PartnershipForm = () => {
	const [phoneNumber, setPhoneNumber] = useState('');
	const [fullName, setFullName] = useState('');
	const [message, setMessage] = useState('');
	const [email, setEmail] = useState('');

	const emailTo = 'partnership@ishangry.com';
	const subjectEmail = `Moon Chicken Partnership - ${fullName}`;
	const bodyEmail = `Full Name: ${fullName}%0D%0AEmail: ${email}%0D%0APhone Number: ${phoneNumber}%0D%0AMessage: ${message || '-'}`;

	const bodyElementRef = useRef(document.querySelector('body'));
	const {width} = useResizeDetector({
		handleHeight: false,
		targetRef: bodyElementRef,
	});
	const enableMagneticButtonEffect = width > parseInt(styles.mobileBreakPoint);

	return (
		<div className={styles.PartnershipForm}>
			<TextField
				label={'Full Name'}
				onChange={setFullName}
				placeholder={'write your full name'}
				id={'fullname'}
				type='text'
				isRequired
				className={styles.PartnershipForm__field} />
			<TextField
				label={'Email Address'}
				onChange={setEmail}
				placeholder={'example: charleswil@gmail.com'}
				id={'email'}
				type='email'
				isRequired
				className={styles.PartnershipForm__field} />
			<TextField
				id="phone"
				label="Phone Number (Whatsapp)"
				onChange={setPhoneNumber}
				placeholder="example: 081234567890"
				type="text"
				isRequired
				className={styles.PartnershipForm__field} />
			<TextField
				label={'Message'}
				onChange={setMessage}
				placeholder={'Write your message'}
				id={'message'}
				type='textarea'
				className={styles.PartnershipForm__field} />
			<a
				href={`mailto:${emailTo}?subject=${subjectEmail}p&body=${bodyEmail}`}>
				<Button
					label="SIGN UP NOW"
					id="partnership-sign-up-button"
					enableMagneticEffect={enableMagneticButtonEffect} />
			</a>
		</div>
	);
};

export default PartnershipForm;
