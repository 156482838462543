import {useRef, useEffect} from 'react';

// Local styles
import styles from './background.module.scss';

// Global assets
import JPGHomeAboutPlanet from 'assets/images/home-about-planet.jpg';
import PNGHomeAboutMeteorSmall from 'assets/images/home-about-meteor-small.png';
import PNGMenuMeteor6 from 'assets/images/menu-meteor-6.png';
import PNGMenuMeteor5 from 'assets/images/menu-meteor-5.png';

// Global utils
import {parallaxMeteor} from 'utils/index';

const Background = () => {
	const smallMeteor = useRef(null);
	const secondSmallMeteor = useRef(null);
	const largeMeteor = useRef(null);
	// Hooks
	useEffect(() => {
		parallaxMeteor({
			smallMeteor: smallMeteor.current,
			largeMeteor: largeMeteor.current,
		});
		parallaxMeteor({
			smallMeteor: secondSmallMeteor.current,
		});
	}, []);
	// Render
	return (
		<div className={styles.Background}>
			<div className={styles.Background__backgroundImage}>
				<img
					alt="Small Meteor"
					src={PNGHomeAboutMeteorSmall}
					className={styles.Background__backgroundImage__smallMeteorTop}
					ref={smallMeteor} />
				<div className={styles.Background__backgroundImage__planet}>
					<img
						alt="Planet"
						src={JPGHomeAboutPlanet} />
				</div>
				<img
					alt="Small Meteor"
					src={PNGMenuMeteor5}
					className={styles.Background__backgroundImage__meteor5}
					ref={secondSmallMeteor} />
				<img
					alt="Large Meteor"
					src={PNGMenuMeteor6}
					className={styles.Background__backgroundImage__meteor6}
					ref={largeMeteor} />
			</div>
			<div className={styles.Background__lines}>
				<div className={styles.Background__lines__mainLines} />
				<div className={styles.Background__lines__verticalLine} />
				<div className={styles.Background__lines__bestSellerLines} />
			</div>
		</div>
	);
};

export default Background;
