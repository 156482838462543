// Local style
import styles from './description.module.scss';

// Global components
import ScrollIndicator from 'components/others/Scrollindicator';

const Description = () => {
	return (
		<div className={styles.Description}>
			<div className={styles.Description__title}>
				<h1>Want to <span className={styles.Description__title__accent}>be a part of </span>
					our outlet expansion?</h1>
			</div>
			<ScrollIndicator className={styles.Description__scrollIndicator} />
		</div>
	);
};

export default Description;