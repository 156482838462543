// Local style
import styles from './hero.module.scss';

// Local components
import Background from './Background';
import Description from './Description';

const Hero = () => {
	return (
		<div className={styles.Hero}>
			<Background />
			<Description />
		</div>
	);
};

export default Hero;
