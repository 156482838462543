import {forwardRef, useEffect, useRef} from 'react';
import {useLocation, useHistory} from 'react-router-dom';
import {useResizeDetector} from 'react-resize-detector';
import PropTypes from 'prop-types';
import {gsap} from 'gsap';
import clsx from 'clsx';

// Local styles
import styles from './menu.module.scss';

const Menu = forwardRef((props, ref) => {
	const isDesktopView = useRef(window.outerWidth > parseInt(styles.mobileBreakPoint));
	const bodyElementRef = useRef(document.querySelector('body'));
	const menuContainerRef = useRef(null);
	const isMenuVisible = useRef(false);
	const menuBodyRef = useRef(null);
	const {width} = useResizeDetector({
		handleHeight: false,
		targetRef: bodyElementRef,
	});
	const location = useLocation();
	const history = useHistory();
	// Methods
	const widthResizeSensor = () => {
		if (width) {
			const isDesktop = width > parseInt(styles.mobileBreakPoint);
			if (isDesktop && isDesktop !== isDesktopView.current && isMenuVisible.current) {
				bodyElementRef.current.style.removeProperty('overflow');
				menuBodyRef.current.removeAttribute('style');
				isMenuVisible.current = false;
				props.onForceClose();
			}
			isDesktopView.current = isDesktop;
		}
	};
	const openMenu = () => {
		bodyElementRef.current.style.overflow = 'hidden';
		gsap.killTweensOf(menuBodyRef.current);
		gsap.timeline({
			onComplete: () => isMenuVisible.current = true,
		})
			.set(menuContainerRef.current.children, {
				opacity: 0,
				y: 40,
			})
			.to(menuBodyRef.current, {
				duration: props.containerTransitionDuration,
				height: styles.containerHeight,
				ease: 'power3.outout',
				display: 'flex',
				opacity: 1,
			})
			.to(menuContainerRef.current.children, {
				duration: props.containerTransitionDuration,
				ease: 'power3.outout',
				stagger: 0.1,
				opacity: 1,
				y: 0,
			});
	};
	const closeMenu = (callback = () => null) => {
		bodyElementRef.current.style.removeProperty('overflow');
		gsap.killTweensOf(menuBodyRef.current);
		gsap.timeline({
			onComplete: () => {
				isMenuVisible.current = false;
				callback('onClosed');
			},
		})
			.to(menuContainerRef.current.children, {
				duration: props.containerTransitionDuration,
				ease: 'power3.outout',
				stagger: -0.075,
				opacity: 0,
				y: 40,
			})
			.to(menuBodyRef.current, {
				onStart: () => callback('onStartClosingContainer'),
				duration: props.containerTransitionDuration,
				ease: 'power3.outout',
				display: 'none',
				opacity: 0,
				height: 0,
			});
	};
	const setRef = () => {
		const refObject = {
			open: openMenu,
			close: closeMenu,
		};
		if (ref && Object.prototype.hasOwnProperty.call(ref, 'current')) {
			ref.current = refObject;
		} else if (typeof ref === 'function') {
			ref(refObject);
		}
	};
	// Event handler methods
	const onClickMenuItem = path => {
		props.closeMenu(responseType => {
			responseType === 'onClosed' && history.push(path);
		});
	};
	// Hooks
	useEffect(() => {
		setRef();
	}, []);
	useEffect(() => {
		widthResizeSensor();
	}, [width]);
	// Render
	return (
		<div
			ref={menuBodyRef}
			className={styles.Menu}>
			<div className={styles.Menu__contentContainer}>
				<div
					ref={menuContainerRef}
					className={styles.Menu__contentContainer__headerMenuLinks}>
					{props.links.map((each, i) => {
						const isLinkActive = each.path === location.pathname;
						return (
							<a
								key={i}
								id={`header-dropdown-${each.id}`}
								onClick={() => onClickMenuItem(each.path)}
								className={clsx(
									{
										[styles['Menu__contentContainer__headerMenuLinks--active']]: isLinkActive,
									},
								)}>
								{each.label}
							</a>
						);
					})}
				</div>
			</div>
		</div>
	);
});

Menu.defaultProps = {
	links: [],
	closeMenu: () => null,
	onForceClose: () => null,
	containerTransitionDuration: 0.6,
};

Menu.propTypes = {
	links: PropTypes.array,
	closeMenu: PropTypes.func,
	onForceClose: PropTypes.func,
	containerTransitionDuration: PropTypes.number,
};

export default Menu;
