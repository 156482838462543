import PropTypes from 'prop-types';
import clsx from 'clsx';

// Global components
import CloudinaryImage from 'components/core/CloudinaryImage';

// Local style
import styles from './description.module.scss';

const DescriptionDetail = props => {
	return (
		<div className={clsx(styles.DescriptionDetail, props.className)}>
			<div className={styles.DescriptionDetail__text}>
				<p>Menu</p>
				<p>
					Bringing the best flavors from all over the galaxy, to you on Earth.
				</p>
			</div>
			<CloudinaryImage
				imageId="menu-hero-banner"
				className={styles.DescriptionDetail__image} />
		</div>
	);
};

DescriptionDetail.propTypes = {
	className: PropTypes.string,
};

export default DescriptionDetail;
