import {useRef, useEffect, forwardRef} from 'react';
import {gsap} from 'gsap';

// Global components
import CloudinaryImage from 'components/core/CloudinaryImage';

// Global assets
import SVGHomeHeroLine from 'assets/vectors/home-hero-line.svg';

// Local styles
import styles from './background.module.scss';

const Background = forwardRef((props, ref) => {
	let activeHeroImageIndex = 0;
	const intervalTransitionDuration = 5000;
	const heroImageContainerRef = useRef(null);
	const intervalHeroImage = useRef(null);
	const partnershipHeroImages = [
		'partnership-hero-1',
		'partnership-hero-2',
		'partnership-hero-3',
	];
	// Methods
	const setRef = () => {
		const refObject = {
			startLoop: startHeroImagesLoop,
		};
		if (ref && Object.prototype.hasOwnProperty.call(ref, 'current')) {
			ref.current = refObject;
		} else if (typeof ref === 'function') {
			ref(refObject);
		}
	};
	const getNextActiveHeroImageIndex = () => {
		return activeHeroImageIndex === partnershipHeroImages.length - 1
			? 0
			: activeHeroImageIndex + 1;
	};
	const swapNextHeroImage = () => {
		const transitionDuration = 2;
		const heroImageElementArray = [...heroImageContainerRef.current.children];
		const nextActiveHeroImageIndex = getNextActiveHeroImageIndex();
		gsap.timeline()
			.to(heroImageElementArray[activeHeroImageIndex], {
				duration: transitionDuration,
				display: 'none',
				opacity: 0,
			})
			.to(heroImageElementArray[nextActiveHeroImageIndex], {
				duration: transitionDuration,
				display: 'block',
				opacity: 0.6,
				onComplete: () => {
					activeHeroImageIndex = nextActiveHeroImageIndex;
				},
			}, '<');
	};
	const startHeroImagesLoop = () => {
		intervalHeroImage.current = setInterval(() => {
			swapNextHeroImage();
		}, intervalTransitionDuration);
	};
	// Hooks
	useEffect(() => {
		setRef();
		startHeroImagesLoop();
		return () => clearInterval(intervalHeroImage.current);
	}, []);
	// Render
	return (
		<div className={styles.Background}>
			<div className={styles.Background__backgroundImage}>
				<div
					ref={heroImageContainerRef}
					className={styles.Background__backgroundImage__imageContainer}>
					{partnershipHeroImages.map((eachImage, i) => (
						<CloudinaryImage
							key={i}
							imageId={eachImage} />
					))}
				</div>
				<div className={styles.Background__backgroundImage__storeTexture} />
			</div>
			<img
				alt="Line"
				src={SVGHomeHeroLine}
				className={styles.Background__gridLine} />
			<div className={styles.Background__filter} />
		</div>
	);
});

export default Background;
