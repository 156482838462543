// Local components
import FlavorsDetail from './FlavorsDetail';
import AvailabilityDetails from './AvailabilityDetails';
import Background from './Background';

// Local styles
import styles from './flavors.module.scss';

const Flavors = () => {
	return (
		<div className={styles.Flavors}>
			<Background />
			<div className={styles.Flavors__content}>
				<FlavorsDetail />
				<AvailabilityDetails />
			</div>
		</div>
	);
};

export default Flavors;
