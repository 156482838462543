// Global components
import CloudinaryImage from 'components/core/CloudinaryImage';

// Local styles
import styles from './availabilityDetails.module.scss';

const AvailabilityDetails = () => {
	const availablePlatforms = [
		{link: '/', image: 'menu-logo-GrabFood', label: 'GrabFood'},
		{link: '/', image: 'menu-logo-GoFood', label: 'GoFood'},
		{link: '/', image: 'menu-logo-ShopeeFood', label: 'ShopeeFood'},
		{link: '/', image: 'menu-logo-HangryApp', label: 'HangryApp'},
	];
	// Render
	return (
		<div className={styles.AvailabilityDetails}>
			<h1 className={styles.AvailabilityDetails__title}>
				Available via
			</h1>
			<div className={styles.AvailabilityDetails__content}>
				{availablePlatforms.map(item => {
					return (
						<a
							href={item.link}
							key={item.label}
							className={styles.AvailabilityDetails__content__image}>
							<CloudinaryImage
								imageId={item.image}
								className={styles[`AvailabilityDetails__content__image__${item.label}`]} />
						</a>
					);
				})}
			</div>
		</div>
	);
};

export default AvailabilityDetails;
