import {useRef} from 'react';
import {useResizeDetector} from 'react-resize-detector';

// Local style
import styles from './hero.module.scss';

// Global components
import ScrollIndicator from 'components/others/Scrollindicator';

// Local components
import Background from './Background';
import MobileBackground from './MobileBackground';
import DescriptionDetail from './DescriptionDetail';

const Hero = () => {
	const bodyElementRef = useRef(document.querySelector('body'));
	const {width} = useResizeDetector({
		handleHeight: false,
		targetRef: bodyElementRef,
	});
	// Render
	return (
		<div className={styles.Hero} >
			{width > parseInt(styles.mobileBreakPoint) ? <Background /> : <MobileBackground />}
			<div className={styles.Hero__contentContainer}>
				<DescriptionDetail />
				<ScrollIndicator className={styles.Hero__contentContainer__scrollIndicator} />
			</div>
		</div>
	);
};

export default Hero;
