import {useEffect, useRef} from 'react';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import PropTypes from 'prop-types';
import {gsap} from 'gsap';
import clsx from 'clsx';

// Global components
import CloudinaryImage from 'components/core/CloudinaryImage';
import ProgressBar from 'components/core/ProgressBar';

// Local styles
import styles from './flavorsVariant.module.scss';

gsap.registerPlugin(ScrollTrigger);

const FlavorsVariant = props => {
	const flavorVariantRef = useRef(null);
	const richnessBarRef = useRef(null);
	const sweetBarRef = useRef(null);
	const heatBarRef = useRef(null);
	const progressBarValues = [
		props.richnessLevel,
		props.sweetLevel,
		props.heatLevel,
	];
	// Hooks
	useEffect(() => {
		setTimeout(() => {
			if (richnessBarRef.current && sweetBarRef.current && heatBarRef.current) {
				gsap.killTweensOf([
					richnessBarRef.current,
					sweetBarRef.current,
					heatBarRef.current,
				]);
				gsap.fromTo([
					richnessBarRef.current,
					sweetBarRef.current,
					heatBarRef.current,
				], {
					width: 0,
				}, {
					width: index => `${progressBarValues[index]}%`,
					scrollTrigger: {
						trigger: flavorVariantRef.current,
						start: '75% bottom',
						markers: false,
					},
					duration: 0.75,
				});
			}
		}, 500);
	}, []);
	// Render
	return (
		<div
			ref={flavorVariantRef}
			className={clsx(
				styles.FlavorsVariant,
				props.className,
			)}>
			<div className={styles.FlavorsVariant__logo}>
				<CloudinaryImage imageId={props.logo} />
			</div>
			<div className={styles.FlavorsVariant__content}>
				<h3 className={styles.FlavorsVariant__content__title}>
					{props.title}
				</h3>

				<div className={styles.FlavorsVariant__content__level}>
					<p className={styles.FlavorsVariant__content__level__label}>
						RICHNESS
					</p>
					<ProgressBar
						ref={richnessBarRef}
						className={styles.FlavorsVariant__content__level__progressBar} />
				</div>

				<div className={styles.FlavorsVariant__content__level}>
					<p className={styles.FlavorsVariant__content__level__label}>
						SWEET
					</p>
					<ProgressBar
						ref={sweetBarRef}
						className={styles.FlavorsVariant__content__level__progressBar} />
				</div>

				<div className={styles.FlavorsVariant__content__level}>
					<p className={styles.FlavorsVariant__content__level__label}>
						HEAT
					</p>
					<ProgressBar
						ref={heatBarRef}
						className={styles.FlavorsVariant__content__level__progressBar} />
				</div>
			</div>
		</div>
	);
};

FlavorsVariant.defaultProps = {
	richnessLevel: 0,
	sweetLevel: 0,
	heatLevel: 0,
};

FlavorsVariant.propTypes = {
	title: PropTypes.string,
	className: PropTypes.string,
	logo: PropTypes.string,
	richnessLevel: PropTypes.number,
	sweetLevel: PropTypes.number,
	heatLevel: PropTypes.number,
};

export default FlavorsVariant;
