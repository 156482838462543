import PropTypes from 'prop-types';

// Local components
import FlavorsVariant from '../FlavorsVariant';

// Local styles
import styles from './flavorsContent.module.scss';

const FlavorsContentDesktop = props => {
	return (
		<div className={styles.FlavorsContent}>
			{
				props.flavors.map(flavor => {
					return (
						<FlavorsVariant
							title={flavor.title}
							logo={flavor.logo}
							richnessLevel={flavor.richnessLevel}
							sweetLevel={flavor.sweetLevel}
							heatLevel={flavor.heatLevel}
							key={flavor.title}
							className={styles.FlavorsContent__variantCard}
						/>
					);
				})
			}
		</div>
	);
};

FlavorsContentDesktop.defaultProps = {
	flavors: [],
};

FlavorsContentDesktop.propTypes = {
	flavors: PropTypes.arrayOf(PropTypes.object),
};

export default FlavorsContentDesktop;