import PropTypes from 'prop-types';
import clsx from 'clsx';

// Local styles
import styles from './mobileBackground.module.scss';

// Global assets
import SVGHeaderMenuLine from 'assets/vectors/header-menu-line.svg';
import JPGHeaderMenuPlanet from 'assets/images/header-menu-planet.jpg';
import PNGHeaderMenuMeteorSmall from 'assets/images/home-hero-meteor-small.png';
import PNGHeaderMenuMeteorLarge from 'assets/images/home-hero-meteor-large.png';
import PNGHeaderMenuPlanetTexture from 'assets/images/header-menu-planet-texture.png';
import PNGHomeHeroMeteor3 from 'assets/images/menu-meteor-3.png';

const MobileBackground = props => {
	return (
		<div className={clsx(styles.MenuBackground, props.className)}>
			<div className={styles.MenuBackground__imageBackground}>
				<img
					alt="Planet"
					src={JPGHeaderMenuPlanet}
					className={styles.MenuBackground__imageBackground__planet} />
				<img
					alt="Planet Texture"
					src={PNGHeaderMenuPlanetTexture}
					className={styles.MenuBackground__imageBackground__planetTexture} />
				<img
					alt="Small Meteor"
					src={PNGHeaderMenuMeteorSmall}
					className={styles.MenuBackground__imageBackground__smallMeteor}
					id='meteorSmall' />
				<img
					alt="Large Meteor"
					src={PNGHeaderMenuMeteorLarge}
					className={styles.MenuBackground__imageBackground__largeMeteor}
					id='meteorLarge' />
				<img
					alt="Meteor Large"
					src={PNGHomeHeroMeteor3}
					className={styles.MenuBackground__imageBackground__meteor3}
					id='meteor3' />
			</div>
			<div className={styles.MenuBackground__backgroundFilter}>
				<div className={styles.MenuBackground__backgroundFilter__innerFilter}>
					<img
						alt="Line"
						src={SVGHeaderMenuLine}
						className={styles.MenuBackground__backgroundFilter__innerFilter__gridLine} />
					<div className={styles.MenuBackground__backgroundFilter__innerFilter__bottomGradient} />
				</div>
			</div>
		</div>
	);
};

MobileBackground.propTypes = {
	className: PropTypes.string,
};

export default MobileBackground;
