import {useRef} from 'react';
import {useResizeDetector} from 'react-resize-detector';
import {useHistory} from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// Global components
import Button from 'components/core/Button';

// Local style
import styles from './description.module.scss';

const DescriptionDetail = props => {
	const history = useHistory();
	const {width} = useResizeDetector({
		handleHeight: false,
		targetRef: useRef(document.body),
	});
	const enableMagneticButtonEffect = width > parseInt(styles.mobileBreakPoint);
	// Render
	return (
		<div className={clsx(styles.DescriptionDetail, props.className)}>
			<p>
				Flavors of
				the Galaxy
			</p>
			<p>
				Like the first time humans ever reached the moon: exciting, thrilling, and eye - opening. Taste the galaxy at your fingertips with Moon Chicken.
			</p>
			<Button
				label="EXPLORE MENU"
				id="home-hero-explore-menu"
				onClick={() => history.push('menu')}
				enableMagneticEffect={enableMagneticButtonEffect}
				className={styles.DescriptionDetail__exploreMenuButton} />
		</div>
	);
};

DescriptionDetail.propTypes = {
	className: PropTypes.string,
};

export default DescriptionDetail;
