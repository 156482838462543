import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';

// Local components
import FlavorsVariant from '../FlavorsVariant';

// Local styles
import styles from './flavorsContent.module.scss';

gsap.registerPlugin(ScrollTrigger);

const FlavorsContentMobile = props => {
	const [firstRowRef, setFirstRowRef] = useState(null);
	const [secondRowRef, setSecondRowRef] = useState(null);
	const middle = props.flavors.length/2;
	const width = window.innerWidth;

	useEffect(() => {
		if (firstRowRef && secondRowRef) {
			gsap.killTweensOf(firstRowRef);
			gsap.killTweensOf(secondRowRef);
			gsap.fromTo(
				firstRowRef,
				{
					x: 0,
				},
				{
					x: 2.6 * width,
					scrollTrigger: {
						trigger :firstRowRef,
						scrub : 2,
						start: 'top bottom',
						endTrigger: secondRowRef,
						end: 'bottom top',
						markers: false,
					},
					ease: 'power1.out',
				});

			gsap.fromTo(
				secondRowRef,
				{
					x: 0,
				},
				{
					x: -(3.3 * width),
					scrollTrigger: {
						trigger : firstRowRef,
						scrub : 2,
						start: 'top bottom',
						endTrigger: secondRowRef,
						end: 'bottom top',
					},
					ease: 'power1.out',
				});
		}
	}, [firstRowRef]);
	return (
		<>
			<div
				className={styles.FlavorsContent__first}
				ref={ref => setFirstRowRef(ref)}>
				{
					props.flavors.slice(0,middle).map(flavor => {
						return (
							<FlavorsVariant
								title={flavor.title}
								logo={flavor.logo}
								richnessLevel={flavor.richnessLevel}
								sweetLevel={flavor.sweetLevel}
								heatLevel={flavor.heatLevel}
								key={flavor.title}
								className={styles.FlavorsContent__variantCard}
							/>
						);
					})
				}
			</div>
			<div
				className={styles.FlavorsContent__second}
				ref={ref => setSecondRowRef(ref)} >
				{
					props.flavors.slice(middle, props.flavors.length).map(flavor => {
						return (
							<FlavorsVariant
								title={flavor.title}
								logo={flavor.logo}
								richnessLevel={flavor.richnessLevel}
								sweetLevel={flavor.sweetLevel}
								heatLevel={flavor.heatLevel}
								key={flavor.title}
								className={styles.FlavorsContent__variantCard}
							/>
						);
					})
				}
			</div>
		</>
	);
};

FlavorsContentMobile.defaultProps = {
	flavors: [],
};

FlavorsContentMobile.propTypes = {
	flavors: PropTypes.arrayOf(PropTypes.object),
};

export default FlavorsContentMobile;
