import {useRef, useEffect} from 'react';
import {useResizeDetector} from 'react-resize-detector';

// Global components
import CloudinaryImage from 'components/core/CloudinaryImage';

// Local styles
import styles from './background.module.scss';

// Global assets
import PNGHomeAboutMeteorSmall from 'assets/images/home-about-meteor-small.png';
import PNGHomeAboutMeteorLarge from 'assets/images/home-about-meteor-large-2.png';

// Global utils
import {parallaxMeteor} from 'utils/index';

const Background = () => {
	const smallMeteor = useRef(null);
	const largeMeteor = useRef(null);
	const {width} = useResizeDetector({
		handleHeight: false,
		targetRef: useRef(document.querySelector('body')),
	});
	// Hooks
	useEffect(() => {
		parallaxMeteor({
			smallMeteor: smallMeteor.current,
			largeMeteor: largeMeteor.current,
		});
	}, []);
	// Render
	return (
		<div className={styles.Background}>
			<div className={styles.Background__satelliteContainer}>
				{width ? width > parseInt(styles.mobileBreakPoint) ? (
					<CloudinaryImage imageId="home-expansion-satellite" />
				) : (
					<CloudinaryImage imageId="home-expansion-satellite-mobile" />
				) : null}
			</div>
			<div className={styles.Background__gradientOverlay} />
			<img
				ref={smallMeteor}
				alt="Small Meteor"
				src={PNGHomeAboutMeteorSmall}
				className={styles.Background__smallMeteor} />
			<img
				ref={largeMeteor}
				alt="Large Meteor"
				src={PNGHomeAboutMeteorLarge}
				className={styles.Background__largeMeteor} />
			<div className={styles.Background__gridBackground} />
		</div>
	);
};

export default Background;
