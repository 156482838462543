import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';

const ScrollToTop = () => {
	const {pathname} = useLocation();
	// Hooks
	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);
	// Render nothing
	return null;
};

export default ScrollToTop;
