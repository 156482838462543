import PropTypes from 'prop-types';

// Fontawesome
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLongArrowLeft} from '@fortawesome/pro-light-svg-icons';

// Local styles
import styles from './outletDetails.module.scss';

// Local components
import OutletLocationMapLegend from '../OutletLocationMapLegend';

const OutletDetails = ({
	onBackPress,
	isDesktopView,
	activeLocationIndex,
	locationsData,
	activeOutlet,
	contentRef,
	headerRef,
}) => {
	let outletIndex = parseInt(activeLocationIndex)+1;

	let activeLocation = '';

	if (locationsData[activeLocationIndex]) {
		activeLocation = locationsData[activeLocationIndex]['city'];
	}
	// Render
	return (
		<>
			<div
				ref={headerRef}
				className={styles.OutletDetails__header}>
				<div className={styles.OutletDetails__header__button}>
					<button
						onClick={onBackPress}
						className={styles.OutletDetails__header__button__navigationButton}>
						<FontAwesomeIcon icon={faLongArrowLeft} />
					</button>
					{
						isDesktopView ?(
							<p className={styles.OutletDetails__header__button__label}>
								BACK
							</p>
						):(
							<>
								<p className={styles.OutletDetails__header__button__index}>
									{
										outletIndex < 10 ? (
											`0${outletIndex}/`
										):(
											`${outletIndex}/`
										)
									}
								</p>
								<p className={styles.OutletDetails__header__button__location}>
									{activeLocation}
								</p>
							</>
						)
					}
				</div>
				{
					(!isDesktopView) &&
						<OutletLocationMapLegend />
				}
			</div>
			<div
				ref={contentRef}
				className={styles.OutletDetails__content}>
				<h1 className={styles.OutletDetails__content__title}>
					Moon Chicken by Hangry, <span>{
						activeOutlet[0] &&
					activeOutlet[0]['label']
					}</span>
				</h1>
				<p className={styles.OutletDetails__content__address}>
					{
						activeOutlet[0] &&
						activeOutlet[0]['address']
					}
				</p>
				{(!isDesktopView) &&
					<div className={styles.OutletDetails__content__divider} />}
			</div>
		</>
	);
};

OutletDetails.defaultProps = {
	onBackPress: () => null,
	isDesktopView: true,
	activeLocationIndex: -1,
	locationsData: [],
	activeOutlet: [],
	headerRef: null,
	contentRef: null,
};

OutletDetails.propTypes = {
	onBackPress: PropTypes.func,
	isDesktopView: PropTypes.bool,
	activeLocationIndex: PropTypes.number,
	activeOutlet: PropTypes.arrayOf(PropTypes.object),
	locationsData: PropTypes.arrayOf(PropTypes.object),
	headerRef: PropTypes.shape({current: PropTypes.instanceOf(Element)}),
	contentRef: PropTypes.shape({current: PropTypes.instanceOf(Element)}),
};

export default OutletDetails;
