import {forwardRef} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// Local styles
import styles from './progressBar.module.scss';

const ProgressBar = forwardRef((props, ref) => {
	// Render
	return (
		<div className={clsx(
			styles.ProgressBar,
			props.className,
		)}>
			<div
				ref={ref}
				className={styles.ProgressBar__bar}
				style={{'width': `${props.percentage}%`}} />
		</div>
	);
});

ProgressBar.defaultProps = {
	percentage: 0,
};

ProgressBar.propTypes = {
	className: PropTypes.string,
	percentage: PropTypes.number,
};

export default ProgressBar;
